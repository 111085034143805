import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select/creatable';
import { closeModal } from '@components/modal';
import { toast } from 'react-toastify';
import translate from '@data/translations';
import {
    PosItemModel,
    SKUType,
    SelectOptionAndValue,
    // StockKeepingUnitEditModel,
    StockKeepingUnitModel,
    TaxModel,
    UnitOfMeasure,
    PaginatedData,
    SKUTypeName,
} from '@common/modelDefinition';
import {
    checkIfBarcodeOrCodeIsUnique,
    getSKUItems,
    createOrEditSkuItem,
    createOrUpdatePosItemAndSkuPluRate,
} from '../api';
import { StockController } from '../stockController';
//@ts-ignore
import _ from 'lodash';
import { renderFirstAvailablePlu } from '@pages/priceList/pos/posItem/form';
import useStore from '@data/state/zustand';
import ConfirmButtons from '@components/buttons/confirmButtons';

const ctrl = new StockController();

export const unitOfMeasure: UnitOfMeasure = {
    piece: 'Piece',
    kg: 'kg (kilogram)',
    l: 'l (liter)',
    m: 'm (meter)',
    kWh: 'kWh (kilowatt-hour)',
};

var emptyFormTemplate = {
    unitType: SKUType.beverage,
    barcode: null,
    code: '',
    name: '',
    description: '',
    category: null,
    salesPrice: 0,
    grossMarginPercentage: '0',
    netoMargin: 0,
    calculateSalesPriceOnStockIncrease: false,
    averagePurchasePrice: 0,
    active: true,
    taxId1: null,
    taxId2: null,
    taxId3: null,
    stockMeasureUnit: 'piece',
    purchaseMeasureUnit: null,
    purchaseMeasureUnit_stockUnitMultiplier: null,
    minimumStock: 0,
    replenishStock: 0,
    isCounter: false,
    cumulativeLoss: 0,
    params: null,
};

interface StockKeepingUnitsFormProps {
    skuItemId: number | null | undefined;
    reload: boolean;
    setReload: Function;
    closeFunc?: Function;
}

const StockKeepingUnitsForm = (props: StockKeepingUnitsFormProps) => {
    const { skuItemId, reload, setReload } = props;
    const [data, setData] = useState({ ...emptyFormTemplate } as StockKeepingUnitModel);
    const [skuItems, setSkuItems] = useState([] as StockKeepingUnitModel[]);
    const [categoryValues, setCategoryValues] = useState([] as SelectOptionAndValue[] | null);
    const [categoryOptions, setCategoryOptions] = useState([] as SelectOptionAndValue[]);
    // const [parentSkuItemOptions, setParentSkuItemOptions] = useState([] as SelectOptionAndValue[]);
    const [isCheckboxActive, setIsCheckboxActive] = useState(false);
    const name = useRef(null);
    const code = useRef(null);
    const tax = useRef(null);

    const taxes: TaxModel[] = useStore((appState: any) => appState.model.Tax);
    const invoicingSettings = useStore((appState: any) => appState.invoicingSettings);
    const fiscalGeneralSettings = useStore((appState: any) => appState.fiscal.fiscalGeneralSettings);

    const country = fiscalGeneralSettings && fiscalGeneralSettings.country ? fiscalGeneralSettings.country : '';
    const generalTax = invoicingSettings?.generalTax;

    useEffect(() => {
        const loadData = async () => {
            const { data: _skuItems }: PaginatedData = await getSKUItems(0, 1000);
            setSkuItems([..._skuItems]);

            let _data = { ...data };
            if (skuItemId) {
                //editing
                loadFormData(skuItemId, _skuItems);
            } else {
                _data.taxId1 = generalTax ? generalTax : null;
                setData(_data);
                ctrl.checkValidationInputs(_data, getValidationInputs);
            }

            const _skuItemTags: string[] = [];
            _skuItems.forEach((sku: StockKeepingUnitModel) => {
                if (sku.category) {
                    sku.category.split(';').forEach((o) => {
                        if (!_skuItemTags.includes(o)) {
                            _skuItemTags.push(o);
                        }
                    });
                }
            });
            const _tagsOptions: SelectOptionAndValue[] = [];
            _skuItemTags.forEach((t) => {
                const obj: SelectOptionAndValue = {
                    value: t,
                    label: t,
                };
                _tagsOptions.push(obj);
            });
            setCategoryOptions(_tagsOptions);

            // const _parentSkuOptions: SelectOptionAndValue[] = [
            //     {
            //         value: 'null',
            //         label: 'None',
            //     },
            // ];
            // _skuItems.forEach((t) => {
            //     const obj: SelectOptionAndValue = {
            //         value: t?.id?.toString(),
            //         label: t?.name,
            //     };
            //     _parentSkuOptions.push(obj);
            // });
            // setParentSkuItemOptions(_parentSkuOptions);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [skuItemId]);

    const loadFormData = async (skuItemId: number | null | undefined, _skuItems = skuItems) => {
        if (_skuItems && !_.isUndefined(skuItemId) && skuItemId !== null && _.isNaN(skuItemId) === false) {
            const currentData = _skuItems.find((t: StockKeepingUnitModel) => t.id === Number(skuItemId));
            const _category = currentData?.category ? currentData.category.split(';') : [];
            const _tagsValue: SelectOptionAndValue[] = [];
            _category.forEach((t: string) => {
                const obj: SelectOptionAndValue = {
                    value: t,
                    label: t,
                };
                _tagsValue.push(obj);
            });
            setCategoryValues(_tagsValue);

            if (currentData) {
                setData(currentData);
                if (currentData.purchaseMeasureUnit) {
                    setIsCheckboxActive(true);
                } else {
                    setIsCheckboxActive(false);
                }
            }
            ctrl.checkValidationInputs(currentData, getValidationInputs);
        }
    };

    const handleInputChange = (e: any) => {
        let _data: any = { ...data };
        if (e.target.type === 'text') {
            _data[e.target.name] = e.target.value;
            setData(_data);
        } else if (e.target.type === 'number') {
            _data[e.target.name] = parseFloat(e.target.value);
            setData(_data);
        }
        if (e.target.name === 'name' || e.target.name === 'code') {
            ctrl.checkValidationInputs(_data, getValidationInputs);
        }
    };

    const handleSelectChange = (e: any) => {
        let _data: any = { ...data };
        _data[e.target.name] = e.target.name === 'unitType' ? Number(e.target.value) : e.target.value;
        if (e.target.name === 'taxId1' || e.target.name === 'taxId2' || e.target.name === 'taxId3') {
            if (isNaN(parseFloat(e.target.value))) {
                _data[e.target.name] = null;
            }
        }

        if (e.target.name === 'stockMeasureUnit' && e.target.value === 'piece') {
            _data.purchaseMeasureUnit = 'piece';
            _data.purchaseMeasureUnit_stockUnitMultiplier = 1;
            setIsCheckboxActive(false);
        } else if (e.target.name === 'stockMeasureUnit' && e.target.value !== 'piece') {
            _data.purchaseMeasureUnit = 'piece';
            _data.purchaseMeasureUnit_stockUnitMultiplier = 1;
            setIsCheckboxActive(true);
        }
        setData(_data);
    };

    const handleCategoryChange = (_category: any) => {
        let category: string[] = [];
        let categoryParsed: string | null = null;
        if (_category !== null) {
            _category.forEach((t: SelectOptionAndValue) => {
                category.push(t.value);
            });
            categoryParsed = category.join(';');
        } else {
            categoryParsed = null;
        }
        let _data = { ...data };
        _data['category'] = categoryParsed;
        setCategoryValues(_category);
        setData(_data);
    };

    const handleIsActiveOrIsCounter = (name: string) => {
        let _data = { ...data };
        if (name === 'active') {
            _data['active'] = !_data.active;
        } else if (name === 'isCounter') {
            _data['isCounter'] = !_data.isCounter;
            if (_data.isCounter) {
                _data.purchaseMeasureUnit = null;
                _data.purchaseMeasureUnit_stockUnitMultiplier = null;
                _data.minimumStock = 0;
                _data.replenishStock = 0;
                setIsCheckboxActive(false);
            }
        }
        setData(_data);
    };

    const handlePurchase = () => {
        let _data = { ...data };
        if (!isCheckboxActive) {
            _data.purchaseMeasureUnit = 'piece';
            _data.purchaseMeasureUnit_stockUnitMultiplier = 1;
        } else {
            _data.purchaseMeasureUnit = null;
            _data.purchaseMeasureUnit_stockUnitMultiplier = null;
        }
        setData(_data);
        setIsCheckboxActive(!isCheckboxActive);
    };

    const getValidationInputs = (_data: StockKeepingUnitModel) => {
        const invalidInputs = [],
            validInputs = [];
        if (_data.name === null || _data.name?.trim() === '') {
            invalidInputs.push(name);
        } else {
            validInputs.push(name);
        }
        if (_data.code === null || _data.code?.trim() === '') {
            invalidInputs.push(code);
        } else {
            validInputs.push(code);
        }
        return { invalidInputs, validInputs };
    };

    const saveFormData = async (createPosItem?: boolean) => {
        try {
            let _skuItem: StockKeepingUnitModel = { ...data };
            _skuItem.barcode = _skuItem.barcode === '' ? null : _skuItem.barcode;

            let barcodeRepeats = 0,
                barcodeValid: string | boolean = true,
                codeRepeats = 0; //for null
            if (_skuItem.barcode !== null && _skuItem.barcode !== '') {
                barcodeRepeats = await checkIfBarcodeOrCodeIsUnique(_skuItem.barcode, null, _skuItem.id);
                barcodeValid = isValidBarcode(_skuItem.barcode);
            }

            if (_skuItem.code !== null && _skuItem.code !== '') {
                codeRepeats = await checkIfBarcodeOrCodeIsUnique(null, _skuItem.code, _skuItem.id);
            }
            if (!_skuItem.salesPrice) {
                _skuItem.salesPrice = 0;
                setData(_skuItem);
            }
            if (barcodeRepeats > 0) {
                throw new Error(translate('Barcode already exists!'));
            } else if (!barcodeValid) {
                throw new Error(translate('Barcode is not valid!'));
            } else if (codeRepeats > 0) {
                throw new Error(translate('Code already exists!'));
            } else if (_skuItem.code === '') {
                throw new Error(translate('Code is not valid!'));
            } else if (
                _skuItem?.minimumStock &&
                _skuItem?.replenishStock &&
                Number(_skuItem.replenishStock) < Number(_skuItem.minimumStock)
            ) {
                throw new Error(translate('Replenish stock must be greater or equal minimum stock!'));
            } else {
                if (barcodeValid) {
                    _skuItem.barcode = typeof barcodeValid === 'string' ? barcodeValid : null;
                }
                let validationResults = getValidationInputs(_skuItem);
                ctrl.markInvalidInputRefs(validationResults);
                if (validationResults.invalidInputs.length === 0) {
                    const skuItms = await createOrEditSkuItem(_skuItem);
                    const skuItm = skuItms ? skuItms[0] : null;
                    if (skuItm?.id && skuItm?.taxId1 && createPosItem) {
                        const _plu = await renderFirstAvailablePlu();
                        const newPosItem: PosItemModel = {
                            plu: _plu,
                            name: skuItm.name,
                            enabled: true,
                            unitMeasure: skuItm.stockMeasureUnit,
                            price: skuItm.salesPrice,
                            taxId: skuItm.taxId1,
                            tax2Id: skuItm.taxId2,
                            tax3Id: skuItm.taxId3,
                            isComboItem: false,
                            preparationRequired: false,
                            type: 'posTerminal',
                            subtype: SKUTypeName[skuItm.unitType as keyof typeof SKUTypeName],
                            category: skuItm.category,
                        };
                        const skuPluRate: any = {
                            skuId: skuItm.id,
                            skuMeasureCode: skuItm.stockMeasureUnit,
                            pluMeasureCode: skuItm.stockMeasureUnit,
                            multiplier: 1,
                            edit: true,
                        };
                        await createOrUpdatePosItemAndSkuPluRate(newPosItem, [skuPluRate]);
                    }
                    if (!_skuItem.id) {
                        toast(translate('StockKeepingUnit added!'), {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            type: toast.TYPE.SUCCESS,
                        });
                    }
                    setData({ ...emptyFormTemplate });
                    setReload(!reload);
                    closeModal();
                }
            }
        } catch (err: any) {
            toast(err.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
            });
        }
    };

    const isActive = data?.active ? (
        <i className="fa fa-lg mr-8p fa-check-square-o pointer" onClick={() => handleIsActiveOrIsCounter('active')} />
    ) : (
        <i className="fa fa-lg mr-8p fa-square-o pointer" onClick={() => handleIsActiveOrIsCounter('active')} />
    );

    const isPurchaseUnitLikeStockUnit = isCheckboxActive ? (
        <i className="fa fa-lg mr-8p fa-check-square-o pointer" onClick={handlePurchase} />
    ) : (
        <i className="fa fa-lg mr-8p fa-square-o pointer" onClick={handlePurchase} />
    );

    const isCounter = data?.isCounter ? (
        <i
            className="fa fa-lg mr-8p fa-check-square-o pointer"
            onClick={() => handleIsActiveOrIsCounter('isCounter')}
        />
    ) : (
        <i className="fa fa-lg mr-8p fa-square-o pointer" onClick={() => handleIsActiveOrIsCounter('isCounter')} />
    );

    // let parentSkuItemValues: SelectOptionAndValue[] = [];
    // const parentSkuItem = data?.parentId ? skuItems?.find((i) => i.id === data?.parentId) : null;
    // if (parentSkuItem?.id) {
    //     parentSkuItemValues = [
    //         {
    //             value: parentSkuItem?.id?.toString(),
    //             label: parentSkuItem?.name,
    //         },
    //     ];
    // }

    return (
        <div className="">
            <div className="display-flex">
                <div className="mr-4p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Name')} <span className="color-secondary">({translate('required')})</span>
                    </label>
                    <input
                        ref={name}
                        autoComplete="off"
                        name="name"
                        placeholder={translate('Name')}
                        type="text"
                        className="input input-modal"
                        value={data.name ? data.name : ''}
                        onChange={(e: any) => handleInputChange(e)}
                    />
                </div>
                <div className="ml-4p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Type')}
                    </label>
                    <select
                        name="unitType"
                        autoComplete="off"
                        className="input input-modal"
                        value={data.unitType ? data.unitType : ''}
                        onChange={(e: any) => handleSelectChange(e)}
                    >
                        {Object.keys(SKUType).map((key: string) => {
                            if (Number(key)) {
                                return null;
                            } else {
                                return (
                                    <option
                                        key={SKUType[key as keyof typeof SKUType]}
                                        value={SKUType[key as keyof typeof SKUType]}
                                    >
                                        {translate(key)}
                                    </option>
                                );
                            }
                        })}
                    </select>
                </div>
            </div>

            <div className="display-flex mt-12p">
                <div className="mr-4p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Code')} <span className="color-secondary">({translate('required')})</span>
                    </label>
                    <input
                        ref={code}
                        autoComplete="off"
                        name="code"
                        placeholder={translate('Code')}
                        type="text"
                        className="input input-modal"
                        value={data.code ? data.code : ''}
                        onChange={(e: any) => handleInputChange(e)}
                    />
                </div>

                <div className="ml-4p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Barcode')}
                    </label>
                    <input
                        autoComplete="off"
                        name="barcode"
                        placeholder={translate('Barcode')}
                        type="text"
                        className="input input-modal"
                        value={data.barcode ? data.barcode : ''}
                        onChange={(e: any) => handleInputChange(e)}
                    />
                </div>
            </div>

            <div className="display-flex mt-12p">
                <div className="mr-4p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Description')}
                    </label>
                    <input
                        autoComplete="off"
                        name="description"
                        placeholder={translate('Description')}
                        type="text"
                        className="input input-modal"
                        value={data.description ? data.description : ''}
                        onChange={(e: any) => handleInputChange(e)}
                    />
                </div>
                <div className="ml-4p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Sales price')}
                    </label>
                    <input
                        disabled={data?.id ? true : false}
                        autoComplete="off"
                        name="salesPrice"
                        placeholder={translate('Sales price')}
                        type="number"
                        className="input input-modal"
                        value={
                            data?.salesPrice || data.salesPrice === 0
                                ? parseFloat(Number(data.salesPrice)?.toFixed(2))
                                : ''
                        }
                        onChange={(e: any) => handleInputChange(e)}
                    />
                </div>
            </div>

            <div className="mt-12p">
                <label className="text-bold ml-4p" htmlFor="">
                    {translate('Category')}
                </label>
                <div className="">
                    <Select
                        isMulti
                        placeholder={translate('Add category')}
                        options={categoryOptions}
                        onChange={(e: any) => handleCategoryChange(e)}
                        value={categoryValues}
                    ></Select>
                </div>
            </div>

            <div className="mt-12p display-flex align-items-center">
                <div className="">{isCounter}</div>
                <label className="text-bold ml-4p" htmlFor="">
                    {translate('Is counter')}
                </label>
            </div>

            <div className="mt-12p">
                <label className="text-bold ml-4p" htmlFor="">
                    {translate('Stock measure code')}
                </label>
                <select
                    name="stockMeasureUnit"
                    autoComplete="off"
                    className="input input-modal"
                    value={data.stockMeasureUnit ? data.stockMeasureUnit : ''}
                    onChange={(e: any) => handleSelectChange(e)}
                >
                    {Object.keys(unitOfMeasure).map((key) => (
                        <option key={key} value={key}>
                            {translate(unitOfMeasure[key])}
                        </option>
                    ))}
                </select>
            </div>

            {data?.stockMeasureUnit === 'piece' || data?.isCounter ? null : (
                <div className="mt-12p display-flex align-items-center">
                    <div className="">{isPurchaseUnitLikeStockUnit}</div>
                    <label className="text-bold ml-4p mr-8p" htmlFor="">
                        {translate('Purchase measure unit not equal stock measure code')}
                    </label>
                </div>
            )}

            <div className="display-flex mt-12p">
                {isCheckboxActive ? (
                    <div
                        className="mr-4p flex"
                        style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}
                    >
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Purchase measure unit')}
                        </label>
                        <input
                            disabled={true}
                            autoComplete="off"
                            name="purchaseMeasureUnit"
                            placeholder={translate('Purchase measure unit')}
                            type="text"
                            className="input input-modal"
                            value={data.purchaseMeasureUnit ? translate(unitOfMeasure[data.purchaseMeasureUnit]) : ''}
                            onChange={() => {}}
                        />
                    </div>
                ) : null}

                {data.purchaseMeasureUnit && isCheckboxActive ? (
                    <div
                        className="ml-4p flex"
                        style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}
                    >
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Purchase unit to stock unit multiplier')}
                        </label>
                        <input
                            autoComplete="off"
                            name="purchaseMeasureUnit_stockUnitMultiplier"
                            placeholder={translate('Purchase unit to stock unit multiplier')}
                            type="number"
                            className="input input-modal"
                            value={
                                data?.purchaseMeasureUnit_stockUnitMultiplier ||
                                data.purchaseMeasureUnit_stockUnitMultiplier === 0
                                    ? parseFloat(Number(data.purchaseMeasureUnit_stockUnitMultiplier)?.toFixed(2))
                                    : ''
                            }
                            onChange={(e: any) => handleInputChange(e)}
                        />
                    </div>
                ) : null}
            </div>

            {data?.unitType === SKUType.food ? (
                <div className="mt-12p">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Cumulative loss in %')}
                    </label>
                    <input
                        autoComplete="off"
                        name="cumulativeLoss"
                        placeholder={translate('Cumulative loss in %')}
                        type="number"
                        className="input input-modal"
                        value={
                            data?.cumulativeLoss || data.cumulativeLoss === 0
                                ? parseFloat(Number(data.cumulativeLoss)?.toFixed(2))
                                : ''
                        }
                        onChange={(e: any) => handleInputChange(e)}
                    />
                </div>
            ) : null}

            <div className="display-flex mt-12p">
                {data?.isCounter ? null : (
                    <div className="mr-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Minimum stock')}
                        </label>
                        <input
                            autoComplete="off"
                            name="minimumStock"
                            placeholder={translate('Minimum stock')}
                            type="number"
                            className="input input-modal"
                            value={
                                data?.minimumStock || data.minimumStock === 0
                                    ? parseFloat(Number(data.minimumStock)?.toFixed(2))
                                    : ''
                            }
                            onChange={(e: any) => handleInputChange(e)}
                        />
                    </div>
                )}
                {data?.isCounter ? null : (
                    <div className="ml-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Replenish stock')}
                        </label>
                        <input
                            autoComplete="off"
                            name="replenishStock"
                            placeholder={translate('Replenish stock')}
                            type="number"
                            className="input input-modal"
                            value={
                                data?.replenishStock || data.replenishStock === 0
                                    ? parseFloat(Number(data.replenishStock)?.toFixed(2))
                                    : ''
                            }
                            onChange={(e: any) => handleInputChange(e)}
                        />
                    </div>
                )}
            </div>

            <div className="display-flex mt-12p">
                <div className="mr-4p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Tax')}:
                    </label>
                    <select
                        ref={tax}
                        name="taxId1"
                        className="input input-modal"
                        onChange={(e: any) => handleSelectChange(e)}
                        value={data.taxId1 ? data.taxId1 : ''}
                    >
                        <option>{translate('No tax')}</option>
                        {taxes?.map((t) => {
                            if (t.isActive) {
                                return t.id !== Number(data.taxId2) && t.id !== Number(data.taxId3) ? (
                                    <option key={t.id} value={t.id}>
                                        {t.name}
                                    </option>
                                ) : null;
                            }
                            return null;
                        })}
                    </select>
                </div>

                <div className="mr-4p ml-4p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Tax 2')}:
                    </label>
                    <select
                        disabled={country === 'BIH' ? true : false}
                        name="taxId2"
                        className="input input-modal"
                        onChange={(e: any) => handleSelectChange(e)}
                        value={data.taxId2 ? data.taxId2 : ''}
                    >
                        <option>{translate('No tax')}</option>
                        {taxes?.map((t) => {
                            if (t.isActive) {
                                return t.id !== Number(data.taxId1) && t.id !== Number(data.taxId3) ? (
                                    <option key={t.id} value={t.id}>
                                        {t.name}
                                    </option>
                                ) : null;
                            }
                            return null;
                        })}
                    </select>
                </div>

                <div className="ml-4p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Tax 3')}:
                    </label>
                    <select
                        disabled={country === 'BIH' ? true : false}
                        name="taxId3"
                        className="input input-modal"
                        onChange={(e: any) => handleSelectChange(e)}
                        value={data.taxId3 ? data.taxId3 : ''}
                    >
                        <option>{translate('No tax')}</option>
                        {taxes?.map((t) => {
                            if (t.isActive) {
                                return t.id !== Number(data.taxId1) && t.id !== Number(data.taxId2) ? (
                                    <option key={t.id} value={t.id}>
                                        {t.name}
                                    </option>
                                ) : null;
                            }
                            return null;
                        })}
                    </select>
                </div>
            </div>

            {/* <div className="mt-12p">
                <label className="text-bold ml-4p" htmlFor="">
                    {translate('Parent stock keeping unit')}
                </label>
                <Select
                    isDisabled={!data?.id ? false : true}
                    placeholder={translate('Parent stock keeping unit')}
                    options={parentSkuItemOptions}
                    onChange={(change: any) => {
                        let _data = { ...data };
                        if (change) {
                            _data.parentId = Number(change.value) ? Number(change.value) : null;
                        } else {
                            _data.parentId = null;
                        }
                        setData({ ..._data });
                    }}
                    value={parentSkuItemValues}
                ></Select>
            </div> */}

            <div className="mt-12p display-flex align-items-center">
                <div className="">{isActive}</div>
                <label className="text-bold ml-4p mr-8p" htmlFor="">
                    {translate('Active')}
                </label>
            </div>
            <br />

            <div className="flex-center-center mt-20p">
                <div data-dismiss="modal" onClick={async () => await saveFormData(true)} className="button-info mr-8p">
                    {translate('Save and create pos item')}
                </div>
                <ConfirmButtons
                    onConfirm={async () => await saveFormData()}
                    onCancel={() => {
                        if (props.closeFunc) {
                            props.closeFunc();
                        } else {
                            closeModal();
                        }
                    }}
                    confirmText={'Save'}
                />
            </div>
        </div>
    );
};

export const isValidBarcode = (eanCode: string) => {
    // Check if only digits
    const ValidChars = '0123456789';

    for (let i = 0; i < eanCode.length; i++) {
        const digit = eanCode.charAt(i);
        if (ValidChars.indexOf(digit) === -1) {
            return false;
        }
    }

    // Add five 0 if the code has only 8 digits
    if (eanCode.length === 8) {
        return false;
        // eanCode = '00000' + eanCode;
    }

    // Add even numbers together
    let even =
        Number(eanCode.charAt(1)) +
        Number(eanCode.charAt(3)) +
        Number(eanCode.charAt(5)) +
        Number(eanCode.charAt(7)) +
        Number(eanCode.charAt(9)) +
        Number(eanCode.charAt(11));

    // Multiply this result by 3
    even = even * 3;

    // Add odd numbers together
    let odd =
        Number(eanCode.charAt(0)) +
        Number(eanCode.charAt(2)) +
        Number(eanCode.charAt(4)) +
        Number(eanCode.charAt(6)) +
        Number(eanCode.charAt(8)) +
        Number(eanCode.charAt(10));

    // Add two totals together
    const total = even + odd;

    // Calculate the checksum
    // Divide total by 10 and store the remainder
    let checksum = total % 10;

    // If result is not 0 then take away 10
    if (checksum !== 0) {
        checksum = 10 - checksum;
    }

    if (eanCode.length === 12) {
        // Check for 12 digits
        eanCode = eanCode + checksum.toString();
        return eanCode;
    } else if (eanCode.length === 13) {
        // Check for 13 digits
        // Get the check number
        const originalCheck = Number(eanCode.substring(eanCode.length - 1));
        // Return the result
        if (checksum !== originalCheck) {
            return false;
        }
        return eanCode;
    } else {
        return false;
    }
};

export default StockKeepingUnitsForm;
