import {
    CompanyModel,
    CustomerModel,
    InvoiceItemPosPresenter,
    PaginatedData,
    PosItemModel,
    PosTerminalConsumption,
    PosTerminalPosItemModel,
} from '@common/modelDefinition';
import rest from '@data/restWrapper';
import useStore from '@data/state/zustand';

const getActivePosTerminalConsumption = () => {
    const posTerminalConsumption: PosTerminalConsumption[] = useStore.getState().state.posTerminal.activeItemsSelection
    return posTerminalConsumption ? [...posTerminalConsumption] : [];
};

export const performFrontendCalculation = () => {
    const posTerminalConsumption: PosTerminalConsumption[] = getActivePosTerminalConsumption();
    const posTermInvoice = useStore.getState().state.posTerminal.invoice;
    let newInvoice = posTermInvoice ? { ...posTermInvoice } : {};
    const invoiceItems = posTerminalConsumption.map((c: PosTerminalConsumption) => {
        return {
            posItemId: c.posItemId,
            itemName: c.itemName ? c.itemName : '',
            quantity: Number(c.quantity),
            bruttoUnitPrice: Number(c.newPrice),
            bruttoUnitsValue: Number(c.quantity) * Number(c.newPrice),
            unitPrice: Number(c.unitPrice),
        };
    });

    let total = 0;
    invoiceItems.forEach((i: InvoiceItemPosPresenter) => {
        total = total + i.bruttoUnitsValue;
    });
    newInvoice.totalBaseValue = total;
    newInvoice.total = total;
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice'], newInvoice);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoiceItems'], invoiceItems);
};

const loadPendingInvoice = async (posTerminalId: number, resId?: number) => {
    voidInvoice();
    //poziva konstruktor i ostalo
    const { invoice, previousInvoices } = await rest('/getPendingInvoicePosTerminal', 'POST', {
        posTerminalId: posTerminalId,
        reservationId: resId,
    });
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice'], invoice);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'previousInvoices'], previousInvoices);
    if (!resId) {
        clearInvoiceProperties();
    }
    //invoiceItems
};

const addInvoiceItem = async (posItem: PosItemModel, quantity?: number) => {
    const { id, price, type, name } = posItem;
    const posTerminalConsumption: PosTerminalConsumption[] = getActivePosTerminalConsumption()
    const invoiceItems = useStore.getState().state.posTerminal.invoiceItems;
    const loadedItem = invoiceItems?.find((i: any) => i.unitPrice === price && i.posItemId === id);
    if (loadedItem === undefined && id) {
        const newItem: PosTerminalConsumption = {
            posItemId: id,
            quantity: quantity ? quantity : 1,
            newPrice: price ? price : 0,
            type: type ? type : 'posTerminal',
            itemName: name,
            unitPrice: price ? price : 0,
            discountPercentage: 0,
        };
        posTerminalConsumption.push(newItem);
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'activeItemsSelection'], posTerminalConsumption);
    } else {
        const quantity_ = quantity ? Number(quantity) : 1;
        const newQuantity = Number(loadedItem?.quantity) + quantity_;
        const newPosTerminalConsumption = posTerminalConsumption.map((p) => {
            if (Number(p.newPrice) === loadedItem?.unitPrice && p.posItemId === loadedItem.posItemId) {
                const editedPosItemConsumption = { ...p };
                editedPosItemConsumption.quantity = newQuantity;
                return editedPosItemConsumption;
            } else {
                return p;
            }
        });
        useStore
            .getState()
            .setNestedProperty(['state', 'posTerminal', 'activeItemsSelection'], newPosTerminalConsumption);
    }
    performFrontendCalculation();
};

const substractInvoiceItem = (invoiceItem: InvoiceItemPosPresenter) => {
    const posTerminalConsumption: PosTerminalConsumption[] = getActivePosTerminalConsumption();
    const targetConsumption = posTerminalConsumption?.find(
        (p: PosTerminalConsumption) =>
            Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId
    );

    if (targetConsumption && targetConsumption?.quantity <= 1) {
        //ukloni ga iz arraya
        const newPosTerminalConsumption = posTerminalConsumption.filter((p: PosTerminalConsumption) => {
            if (Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId) {
                return null;
            } else {
                return p;
            }
        });

        useStore
            .getState()
            .setNestedProperty(['state', 'posTerminal', 'activeItemsSelection'], newPosTerminalConsumption);
    } else {
        const newQuantity = (invoiceItem.quantity ? invoiceItem.quantity : 0) - 1;
        const newPosTerminalConsumption = posTerminalConsumption.map((p: PosTerminalConsumption) => {
            if (Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId) {
                const editedPosItemConsumption = { ...p };
                editedPosItemConsumption.quantity = newQuantity;
                return editedPosItemConsumption;
            } else {
                return p;
            }
        });
        useStore
            .getState()
            .setNestedProperty(['state', 'posTerminal', 'activeItemsSelection'], newPosTerminalConsumption);
    }

    performFrontendCalculation();
};

const invoiceItemAddition = (invoiceItem: InvoiceItemPosPresenter) => {
    const posTerminalConsumption: PosTerminalConsumption[] = getActivePosTerminalConsumption();
    const newQuantity = (invoiceItem.quantity ? invoiceItem.quantity : 0) + 1;
    const newPosTerminalConsumption =
        posTerminalConsumption &&
        posTerminalConsumption?.map((p: PosTerminalConsumption) => {
            if (Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId) {
                const editedPosItemConsumption = { ...p };
                editedPosItemConsumption.quantity = newQuantity;
                return editedPosItemConsumption;
            } else {
                return p;
            }
        });
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'activeItemsSelection'], newPosTerminalConsumption);
    performFrontendCalculation();
};

const removeInvoiceItem = (invoiceItem: InvoiceItemPosPresenter) => {
    const posTerminalConsumption: PosTerminalConsumption[] = getActivePosTerminalConsumption();

    const newPosTerminalConsumption = posTerminalConsumption?.filter((p: PosTerminalConsumption) => {
        if (Number(p.newPrice) === invoiceItem.unitPrice && p.posItemId === invoiceItem.posItemId) {
            return null;
        } else {
            return p;
        }
    });

    useStore.getState().setNestedProperty(['state', 'posTerminal', 'activeItemsSelection'], newPosTerminalConsumption);

    performFrontendCalculation();
};

const setPointOfSaleInputs = async (value: string | number | boolean | null | undefined, type: string) => {
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', ...[type]], value);
};

const setCustomerInputs = async (customer: CustomerModel | null) => {
    if (customer) {
        const customerInfo = getCustomerInfo(customer);
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'customerId'], customer.id);
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'customerInfo'], customerInfo);
    } else {
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'customerId'], null);
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'customerInfo'], null);
    }
};

const setCompanyInputs = async (company: CompanyModel | null) => {
    if (company) {
        const companyInfo = getCompanyInfo(company);
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'companyId'], company.id);
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'companyInfo'], companyInfo);
    } else {
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'companyId'], null);
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'companyInfo'], null);
    }
};

const getCompanyInfo = (company: CompanyModel) => {
    if (company) {
        const { idNumber, oib, name, address, poNumber, town, country, taxFree } = company;
        const newObj = { idNumber, oib, name, address, poNumber, town, country, type: 'company', taxFree };
        return JSON.stringify(newObj);
    } else {
        return null;
    }
};

const getCustomerInfo = (customer: CustomerModel) => {
    if (customer) {
        const { documentId, firstName, lastName, address, poNumber, town, country } = customer;
        const newObj = { documentId, firstName, lastName, address, poNumber, town, country, type: 'customer' };
        return JSON.stringify(newObj);
    } else {
        return null;
    }
};

const setInvoiceType = async (invoiceType: number) => {
    const invoiceCursor = useStore.getState().state.posTerminal.invoice;
    const editedInvoice = invoiceCursor ? { ...invoiceCursor } : {};
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'invoiceType'], invoiceType);
    const typeOfPayment =
        invoiceType !== 2 && editedInvoice.typeOfPayment === null
            ? 'G'
            : invoiceType === 2 && editedInvoice.typeOfPayment !== null
            ? null
            : editedInvoice.typeOfPayment;
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'typeOfPayment'], typeOfPayment);
};

const issueInvoice = async (reservationId: number | null | undefined) => {
    const invoice = useStore.getState().state.posTerminal.invoice;
    const posTerminalId = useStore.getState().state.posTerminal.invoice.posTerminalId;
    const posTerminalConsumption: PosTerminalConsumption[] = getActivePosTerminalConsumption();
    await rest('/issuePosTerminalInvoice', 'POST', {
        posTerminalId: posTerminalId,
        posTerminalConsumption: posTerminalConsumption,
        reservationId,
        invoice: invoice,
    });
    voidInvoice();
};

const voidInvoice = async () => {
    //izbriši sve sa //activeItemsSelection:{} što i obnovi rekalkulaciju računa, što će za rezultat imati praznu desnu stranu
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'activeItemsSelection'], []);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'filteredItems'], null);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoiceItems'], []);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice'], {});
};

const setCategory = (category: string | null, posItems: PosItemModel[]) => {
    if (category === null) {
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'filteredItems'], null);
    } else {
        const _posItems = posItems.filter((i: PosItemModel) => i.enabled);
        const filtered: PosItemModel[] = [];
        _posItems.forEach((p: PosItemModel) => {
            const tag = p.category ? p.category.split(';') : [];
            tag.forEach((t) => {
                if (t === category) {
                    filtered.push(p);
                }
            });
        });
        useStore.getState().setNestedProperty(['state', 'posTerminal', 'filteredItems'], filtered);
    }
};

const attachItemsToRes = async (reservationId: number) => {
    let invoiceState = useStore.getState().state.posTerminal.invoice
    let invoice = invoiceState ? {...invoiceState} : {}
    invoice.invoiceType = 2;
    const posTerminalId = useStore.getState().state.posTerminal.invoice.posTerminalId;
    const posTerminalConsumption: PosTerminalConsumption[] = getActivePosTerminalConsumption();
    await rest('/issuePosTerminalInvoice', 'POST', {
        posTerminalId: posTerminalId,
        posTerminalConsumption: posTerminalConsumption,
        reservationId,
        invoice,
    });
    voidInvoice();
};

const loadGroupReservationsAndInvoices = async () => {
    const allGroups = await rest(`/getGroupInfosForPOS`,)
   
    return allGroups;
};

const clearInvoiceProperties = async () => {
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'invoiceType'], 0);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'customerId'], null);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'customerInfo'], null);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'companyId'], null);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'companyInfo'], null);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'reservationId'], null);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'groupUuid'], null);
    useStore.getState().setNestedProperty(['state', 'posTerminal', 'invoice', 'typeOfPayment'], 'G');
};

const getPosTerminalPosItems = async (posTerminalId: number, dontIncludePosItem?: boolean) => {
    const terminalsPosItems = dontIncludePosItem
        ? await rest('/getPosTerminalPosItems/' + posTerminalId + '?dontIncludePosItem=true')
        : await rest('/getPosTerminalPosItems/' + posTerminalId);
    return terminalsPosItems;
};

const getPosItems = async (): Promise<PaginatedData> => {
    return await rest('/getPosItemsWithSkuPluRates', 'GET');
};

const attachPosItemToTerminal = async (posItem: PosItemModel, posTerminalId: number) => {
    const attachPosItem = await rest('/attachPosItemToTerminal', 'POST', {
        posItem: posItem,
        posTerminalId: posTerminalId,
    });
    return attachPosItem;
};

const filterPosTerminalPosItems = (
    posItems: PosItemModel[],
    posTerminalPosItems: PosTerminalPosItemModel[],
    posTerminalId: number
) => {
    const filteredPosItems = posItems?.map((p) => {
        const validPosItem = posTerminalPosItems.find((po) => {
            if (
                Number(po.posTerminalId) === Number(posTerminalId) &&
                Number(p.id) === Number(po.posItemId) &&
                p.enabled
            ) {
                return po;
            } else {
                return null;
            }
        });

        if (validPosItem) {
            const newPosItem = { ...p };
            newPosItem.price = Number(validPosItem?.price);
            return newPosItem;
        } else {
            return null;
        }
    });

    const filteredPosItemsWithoutNull: PosItemModel[] = [];
    filteredPosItems.forEach((p) => {
        if (p) {
            filteredPosItemsWithoutNull.push(p);
        }
    });

    return filteredPosItemsWithoutNull;
};

export {
    loadPendingInvoice,
    addInvoiceItem,
    substractInvoiceItem,
    setPointOfSaleInputs,
    issueInvoice,
    setCategory,
    voidInvoice,
    attachItemsToRes,
    setInvoiceType,
    loadGroupReservationsAndInvoices,
    getPosTerminalPosItems,
    filterPosTerminalPosItems,
    removeInvoiceItem,
    setCustomerInputs,
    setCompanyInputs,
    attachPosItemToTerminal,
    invoiceItemAddition,
    getPosItems,
};
