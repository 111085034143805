import React from 'react';
import ActiveReservations from './sidebarActiveReservations';
import { Reservation } from '@pages/newReservations/resController';
import { RoomInfoEditModel } from '@common/modelDefinition';

interface SidebarProps {
    children: any;
    setReservationId: Function;
    toggleSelectRoomForReservation: Function;
    reservations: Reservation[];
    availableToTimestamp: number | null;
    availableFromTimestamp: number;
    searchTerm: string;
    rooms: RoomInfoEditModel[];
    setReservationUuid: Function;
}

const Sidebar = React.memo((props: SidebarProps) => {
    function useWhyDidYouUpdate(name: any, props: any) {
        const previousProps: any = React.useRef();
        React.useEffect(() => {
            if (previousProps.current) {
                const allKeys = Object.keys({ ...previousProps.current, ...props });
                const changes: any = {};
                allKeys.forEach((key) => {
                    if (previousProps.current[key] !== props[key]) {
                        changes[key] = {
                            from: previousProps.current[key],
                            to: props[key],
                        };
                    }
                });

                if (Object.keys(changes).length) {
                }
            }
            previousProps.current = props;
        });
    }

    useWhyDidYouUpdate('ChildComponent', props);

    return (
        <div id="activity-sidebar" className="p-2 bg-white rounded-sm shadow-sm">
            {props.children}
            <ActiveReservations {...props} />
        </div>
    );
});

export default Sidebar;
