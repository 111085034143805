import React, { useEffect, useState, useRef } from 'react';
//@ts-ignore
import _ from 'lodash';
import rest from '@data/restWrapper';
import { saveModelData, getSpecificModelRow } from '@data/actions/modelActions';
//@ts-ignore
import { toast } from 'react-toastify';
import countryCodes from '@data/countryCodes.json';
import { closeModal } from '@components/modal';
import { CompanyModel, CompaniesTypes } from '@common/modelDefinition';
import translate from '@data/translations';
import useStore from '@data/state/zustand';
import ConfirmButtons from '@components/buttons/confirmButtons';

interface CompanyFormProps {
    match?: any;
    companyId?: number | null;
    callback?: Function;
    history?: any;
    cancelHandler?: Function;
    cancelModal?: boolean;
    showPageTitle?: boolean | undefined;
    customHeader?: any;
}

export const companiesTypes = {
    client: 'Company is Client',
    supplier: 'Company is Supplier',
    clientAndSupplier: 'Company is Client & Supplier',
};

const CompanyForm = (props: CompanyFormProps) => {
    const [validationMessage, setValidationMessage] = useState('');
    const [company, setCompany] = useState({
        name: '',
        idNumber: '0000000000000',
        address: '',
        poNumber: '',
        town: '',
        country: '',
        notes: '',
        web: '',
        taxFree: false,
        type: 1,
    } as CompanyModel);

    const fiscalGeneralSettings = useStore((appState: any) => appState.fiscal.fiscalGeneralSettings);

    const name = useRef(null);
    const idNumber = useRef(null);

    useEffect(() => {
        const loadData = async () => {
            let companyId = null;
            if (props.match && props.match.params.companyId) {
                companyId = props.match.params.companyId;
            } else if (props.companyId) {
                companyId = props.companyId;
            }

            if (companyId) {
                let _company = await getSpecificModelRow('Company', companyId);
                _company.params = JSON.parse(_company.params);
                setCompany(_company);
                checkValidationInputs(_company);
            } else {
                checkValidationInputs(company);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props?.match?.params?.companyId, props?.companyId]);

    const handleInputChange = (e: any) => {
        let _company: CompanyModel = { ...company };
        _company[e.target.name as keyof CompanyModel] = e.target.value;
        setCompany(_company);
        if (e.target.name === 'name' || e.target.name === 'idNumber') {
            checkValidationInputs(_company);
        }
    };

    const handleSelectChange = (e: any) => {
        let _company: CompanyModel = { ...company };
        _company[e.target.name as keyof CompanyModel] = CompaniesTypes[e.target.value];
        setCompany(_company);
    };

    const checkValidationInputs = (objToValidate: CompanyModel) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    };

    const markInvalidInputRefs = (validationResults: any) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!classes.includes('is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (classes.includes('is-invalid')) {
                    _.remove(classes, (i: any) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const getValidationInputs = (data: CompanyModel) => {
        const invalidInputs = [],
            validInputs = [];
        // clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(name);
        } else {
            validInputs.push(name);
        }
        if (data.idNumber === null || data.idNumber?.trim() === '') {
            invalidInputs.push(idNumber);
        } else {
            validInputs.push(idNumber);
        }

        return { invalidInputs, validInputs };
    };

    const saveFormData = async () => {
        try {
            let _company = { ...company };
            _company.params = JSON.stringify(_company.params);
            _company.idNumber = _company.idNumber === '' ? null : _company.idNumber;

            let idNumberRepeats = { count: 0 }; //for null
            if (_company.idNumber !== null) {
                idNumberRepeats = await rest('/checkIfCompanyIdNumberIsUnique/' + _company.id, 'POST', {
                    idNumber: _company.idNumber,
                });
            }

            if (idNumberRepeats.count > 0) {
                throw new Error(
                    translate("Field: '") + translate('VAT or ID number') + translate("' is not unique!!!! ")
                );
            } else {
                const validationResults = getValidationInputs(_company);
                markInvalidInputRefs(validationResults);
                if (validationResults.invalidInputs.length === 0) {
                    saveModelData('Company', company)
                        .then((res) => {
                            if (!company.id) {
                                toast(translate('Company created!'), {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                    type: toast.TYPE.SUCCESS,
                                });
                            }
                            if (props.callback) {
                                props.callback(res);
                            } else {
                                if (props.history) {
                                    props.history.push('/companies');
                                }
                            }
                            cancelhandler();
                        })
                        .catch((err) => {
                            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
                        });
                }
            }
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    };

    const cancelHandler = () => {
        if (props.cancelHandler) {
            props.cancelHandler();
        } else {
            if (props.history) {
                props.history.push('/companies');
            }
        }
        cancelhandler();
    };

    const cancelhandler = () => {
        setValidationMessage('');
        setCompany({
            name: '',
            idNumber: '',
            address: '',
            poNumber: '',
            town: '',
            country: '',
            notes: '',
            web: '',
            type: 1,
        });
        if (props.cancelModal === false) {
        } else {
            closeModal();
        }
    };

    const countryOptions = countryCodes.map((c) => {
        return (
            <option value={c['country-code']} key={c['country-code']}>
                {translate(c.name)}
            </option>
        );
    });
    countryOptions.unshift(
        <option key="none" value="">
            {' '}
            -{' '}
        </option>
    );

    return (
        <div className="">
            {props.customHeader || props.customHeader === '' ? props.customHeader : null}
            <div className="">
                <div className="">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Name')}:
                    </label>
                    <input
                        autoFocus
                        ref={name}
                        autoComplete="off"
                        placeholder={translate('Pimatico')}
                        name="name"
                        value={company.name ? company.name : ''}
                        onChange={(e: any) => handleInputChange(e)}
                        type="text"
                        className="input input-modal"
                    />
                </div>

                <div className="display-flex mt-12p">
                    <div className="mr-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('VAT or ID number')}:
                        </label>
                        <input
                            ref={idNumber}
                            autoComplete="off"
                            placeholder={translate('1234567898765')}
                            name="idNumber"
                            value={company.idNumber ? company.idNumber : ''}
                            onChange={(e: any) => handleInputChange(e)}
                            type="text"
                            className="input input-modal"
                        />
                    </div>

                    {fiscalGeneralSettings && fiscalGeneralSettings.country === 'CRO' ? (
                        <div className="ml-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {'OIB'}:
                            </label>
                            <input
                                autoComplete="off"
                                placeholder="134582398724"
                                name="oib"
                                value={company.oib ? company.oib : ''}
                                onChange={(e: any) => handleInputChange(e)}
                                type="text"
                                className="input input-modal"
                            />
                        </div>
                    ) : null}
                </div>

                <div className="display-flex mt-12p">
                    <div className="mr-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Address')}:
                        </label>
                        <input
                            autoComplete="off"
                            name="address"
                            placeholder={translate('Ulica Nike Neznanovica 2')}
                            value={company.address ? company.address : ''}
                            onChange={(e: any) => handleInputChange(e)}
                            type="text"
                            className="input input-modal"
                        />
                    </div>

                    <div className="ml-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Town')}:
                        </label>
                        <input
                            autoComplete="off"
                            name="town"
                            placeholder={translate('Mostar')}
                            value={company.town ? company.town : ''}
                            onChange={handleInputChange.bind(this)}
                            type="text"
                            className="input input-modal"
                        />
                    </div>
                </div>

                <div className="display-flex mt-12p">
                    <div className="mr-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Post office number')}:
                        </label>
                        <input
                            autoComplete="off"
                            name="poNumber"
                            placeholder={translate('55555')}
                            value={company.poNumber ? company.poNumber : ''}
                            onChange={(e: any) => handleInputChange(e)}
                            type="text"
                            className="input input-modal"
                        />
                    </div>

                    <div className="ml-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Country')}:
                        </label>
                        <select
                            name="country"
                            className="input input-modal"
                            value={company.country ? company.country : ''}
                            onChange={handleInputChange}
                        >
                            {countryOptions}
                        </select>
                    </div>
                </div>

                <div className="mt-12p flex">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Type')}
                    </label>
                    <select
                        name="type"
                        autoComplete="off"
                        className="input input-modal"
                        value={company.type ? CompaniesTypes[company.type] : ''}
                        onChange={(e) => handleSelectChange(e)}
                    >
                        {Object.keys(companiesTypes).map((key) => (
                            <option key={key} value={key}>
                                {/* @ts-ignore */}
                                {translate(companiesTypes[key])}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mt-12p">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Web')}:
                    </label>
                    <input
                        autoComplete="off"
                        name="web"
                        placeholder={translate('Web')}
                        value={company.web ? company.web : ''}
                        onChange={(e: any) => handleInputChange(e)}
                        type="text"
                        className="input input-modal"
                    />
                </div>

                <div className="display-flex mt-12p">
                    <div className="mr-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Procurement email')}
                        </label>
                        <input
                            autoComplete="off"
                            name="procurementEmail"
                            placeholder={translate('Procurement email')}
                            type="text"
                            className="input input-modal"
                            value={company.procurementEmail ? company.procurementEmail : ''}
                            onChange={(e: any) => handleInputChange(e)}
                        />
                    </div>

                    <div className="ml-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Sales email')}
                        </label>
                        <input
                            autoComplete="off"
                            name="salesEmail"
                            placeholder={translate('Sales email')}
                            type="text"
                            className="input input-modal"
                            value={company.salesEmail ? company.salesEmail : ''}
                            onChange={(e: any) => handleInputChange(e)}
                        />
                    </div>
                </div>
                <div className="display-flex mt-12p">
                    <div className="mr-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Tech support email')}
                        </label>
                        <input
                            autoComplete="off"
                            name="techSupportEmail"
                            placeholder={translate('Tech support email')}
                            type="text"
                            className="input input-modal"
                            value={company.techSupportEmail ? company.techSupportEmail : ''}
                            onChange={(e: any) => handleInputChange(e)}
                        />
                    </div>

                    <div className="ml-4p flex">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Administrative email')}
                        </label>
                        <input
                            autoComplete="off"
                            name="administrativeEmail"
                            placeholder={translate('Administrative email')}
                            type="text"
                            className="input input-modal"
                            value={company.administrativeEmail ? company.administrativeEmail : ''}
                            onChange={(e: any) => handleInputChange(e)}
                        />
                    </div>
                </div>

                <div className="mt-12p">
                    <i
                        onClick={() => {
                            const editedCompany = { ...company };
                            editedCompany.taxFree = !editedCompany.taxFree;
                            setCompany(editedCompany);
                        }}
                        className={company?.taxFree ? 'fa fa-check-square-o fa-lg mr-8p' : 'fa fa-square-o fa-lg mr-8p'}
                    ></i>
                    <label className="text-bold ml-4p" htmlFor="taxFree">
                        {translate('Tax exemption')}
                    </label>
                </div>
                {/* {company.taxFree ? (
                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-md-4 col-form-label" htmlFor="">
                                        {translate('Select Reason')}:
                                    </label>
                                    <div className="col-md-8">
                                        <select
                                            name="taxFreeReason"
                                            className="input input-modal"
                                            value={selectedNote ? selectedNote : ''}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    setSelectedNote(e.target.value);
                                                    const targetNote: GeneralSettingModel | undefined =
                                                        genericNotes.find(
                                                            (g: GeneralSettingModel) => g.key === e.target.value
                                                        );
                                                    if (targetNote) {
                                                        const newCompany = { ...company };
                                                        const { params } = targetNote;
                                                        if (params) {
                                                            newCompany.notes = params;
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            <React.Fragment>
                                                <option value={'noReason'} key={'noReason'}>
                                                    {translate('No reason')}
                                                </option>
                                                {genericNotes.map((g: any) => {
                                                    return (
                                                        <option value={g.key} key={g.uuid}>
                                                            {g.key}
                                                        </option>
                                                    );
                                                })}
                                            </React.Fragment>
                                        </select>
                                    </div>
                                </div>
                            ) : null} */}
                <div className="mt-12p">
                    <label className="text-bold ml-4p" htmlFor="">
                        {translate('Notes')}:
                    </label>
                    <textarea
                        name="notes"
                        placeholder={translate('Notes')}
                        onChange={(e: any) => handleInputChange(e)}
                        value={company.notes ? company.notes : ''}
                        className="input input-modal"
                    ></textarea>
                </div>

                {validationMessage === '' ? null : (
                    <div className="alert alert-danger" role="alert">
                        {validationMessage}
                    </div>
                )}

                <div className="flex-center-center mt-20p">
                    <ConfirmButtons onConfirm={saveFormData} onCancel={cancelHandler} confirmText={'Save'} />
                </div>
            </div>
        </div>
    );
};

export default CompanyForm;
