import React, { useState, useEffect } from 'react';
import {
    reservationStatusColorsHEX,
    reservationStatusEnumsDesc,
    reservationStatusColorsHEXCondo,
} from '../../data/reservationStatusEnums';
import Timeline from '../../components/timeline/src';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import './legend-checkbox-styling.css';
import { translate } from '../../data/translations';
import { buildMonthsTimebar, buildDaysTimebar } from '../../components/timeline/pimaticoHotelUtils';
import ReservationStatusLegend from './reservationStatusLegend';
import { Reservation, ReservationController } from '@pages/newReservations/resController';
import { ReservationModel } from '@common/modelDefinition';
import useStore from '@data/state/zustand';
import { TaskBackgroundColorEnum, TaskStatusEnumText } from '@pages/householdNew/interfaces';
import { saveOrUpdateReservation } from '@pages/newReservations/api';
import InlineModal from '@components/modalInline';
import ConfirmButtons from '@components/buttons/confirmButtons';
import { unstable_batchedUpdates } from 'react-dom';
//@ts-ignore
const isEqual = (a: any, b: any): boolean => {
    if (a === b) return true;

    if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
        return false;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (const key of keysA) {
        if (!Object.prototype.hasOwnProperty.call(b, key)) return false;
        if (!isEqual(a[key], b[key])) return false;
    }

    return true;
};

const MIN_ZOOM = 2;
const MAX_ZOOM = 100;

const startFrom = new Date().getFullYear() - 1;

let monthsTimebar = buildMonthsTimebar(startFrom, 3, translate('ROOM'));
let daysTimebar = buildDaysTimebar(startFrom, 3, translate('DAY'));
const timebar = [daysTimebar, monthsTimebar];

function getPos(el: any) {
    // yay readability
    for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
    return { x: lx, y: ly };
}

interface ReservationsTimelineProps {
    reservationRoute?: string;
    zoom?: number;
    availableFromTimestamp: number;
    availableToTimestamp: number | null;
    selectedRoomsForReservations: number[];
    toggleSelectRoomForReservation: Function;
    setReservationId: Function;
    setReservationUuid: Function;
    toggleGroupReservations: Function;
    groupReservations: boolean;
    showComponents: any;
    ghostTrack?: any;
    basic?: boolean;
    showLegend?: boolean;
    showMarker?: boolean;
    reservations: Reservation[];
    nowMarker?: any;
    activeReservations: Reservation[];
    rooms: any[];
    style?: any;
    setMousePosition: Function;
    onLoaded?: Function;
    groupedCleaningTasksByRoom?: any;
}
let dragOffsetStart = 0;
let dragOffset = 0;

const ReservationsTimeline = React.memo(
    (props: ReservationsTimelineProps) => {
        useWhyDidYouUpdate('ReservationsTimeline', props);
        let reservationRoute = props.reservationRoute ? props.reservationRoute : 'reservations';
        const open: boolean = true;
        const zoom: number = props.zoom ? props.zoom : 80;
        const [hideReservationStatusEnums, setHideReservationStatusEnums] = useState([3] as number[]);
        const [hideCondoReservations, setHideCondoReservations] = useState(false as boolean);
        const [tableContentHeight, setTableContentHeight] = useState(400 as number);
        const [dragAndDropReservation, setDragAndDropReservation] = React.useState(null as Reservation | null);
        const [dropConfirm, setDropConfirm] = React.useState(null as ReservationModel | null);
        const [ghostElementOnDrop, setGhostElementOnDrop] = React.useState(null as any | null);

        const timelineYearsCount: number = useStore(
            (appState: any) => appState.uiStates.accommodationPriceListStack.timelineYearsCount
        );

        useEffect(() => {
            if (props.onLoaded) {
                props.onLoaded(); // Notify parent that it's fully rendered
            }
            // eslint-disable-next-line
        }, []);

        const getWidth = () => {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        };

        // Usage

        function useWhyDidYouUpdate(name: any, props: any) {
            const previousProps: any = React.useRef();

            useEffect(() => {
                if (previousProps.current) {
                    const allKeys = Object.keys({ ...previousProps.current, ...props });
                    const changes: any = {};
                    allKeys.forEach((key) => {
                        if (previousProps.current[key] !== props[key]) {
                            changes[key] = {
                                from: previousProps.current[key],
                                to: props[key],
                            };
                        }
                    });

                    if (Object.keys(changes).length) {
                    }
                }

                previousProps.current = props;
            });
        }

        useEffect(() => {
            const loadData = async () => {
                const el = document.getElementById('timeline');
                const mainAppDiv = document.getElementById('main-app-div');
                const positionOfTableHeaderElement = getPos(el);
                const heightOfTheSpaceFromTableHeaderToDocumentFooter =
                    (mainAppDiv?.scrollHeight || 0) - positionOfTableHeaderElement.y;
                const _tableContentHeight =
                    heightOfTheSpaceFromTableHeaderToDocumentFooter - 50 - (getWidth() <= 600 ? 64 : 23);
                setTableContentHeight(_tableContentHeight);
            };

            loadData().catch(console.error);
        }, []);

        useEffect(() => {
            const loadData = async () => {
                window.dispatchEvent(new Event('timelineScrollToNow'));
            };

            loadData().catch((err) => {
                console.log(err);
            });
            // eslint-disable-next-line
        }, [props.availableFromTimestamp]);

        const toggleHideReservationStatusEnums = (statusEnum: number) => {
            let _hideReservationStatusEnums = [...hideReservationStatusEnums];
            if (hideReservationStatusEnums.includes(statusEnum)) {
                _hideReservationStatusEnums = hideReservationStatusEnums.filter((r) => r !== statusEnum);
            } else {
                _hideReservationStatusEnums.push(statusEnum);
            }
            setHideReservationStatusEnums([..._hideReservationStatusEnums]);
        };

        const toggleHideCondoReservations = (hideRes: boolean) => {
            setHideCondoReservations(hideRes);
        };

        const getTracksFromRoomsAndReservations = (
            rooms: any[],
            _reservations: Reservation[],
            hideReservationStatusEnums: number[]
        ) => {
            const trackButtonMode = getTrackButtonMode();
            const selectedRooms = new Set(props.selectedRoomsForReservations);
            const hasAvailableFromTimestamp = !!props.availableFromTimestamp;

            // Cache cleaning tasks
            const groupedCleaningTasksByRoom = props.groupedCleaningTasksByRoom || {};

            // Build reservations map by room ID for faster lookup
            const reservationsMap = new Map<number, Reservation[]>();
            for (const res of _reservations) {
                if (!reservationsMap.has(res.roomInfoId)) {
                    reservationsMap.set(res.roomInfoId, []);
                }
                reservationsMap.get(res.roomInfoId)!.push(res);
            }

            return rooms.map((room) => {
                if (!room) return null;

                const roomId = room.id;
                const statusObj = groupedCleaningTasksByRoom[roomId]?.[0] || null;
                const cleaningStatus = statusObj?.taskStatus || 0;
                const cleaningStatusColor = TaskBackgroundColorEnum[cleaningStatus] || TaskBackgroundColorEnum[0];

                let hasButton = true;
                let trackButtonIconOverride = null;

                if (trackButtonMode === 'groupReservationsMode') {
                    if (selectedRooms.has(roomId)) {
                        trackButtonIconOverride = <i className="fa fa-check-square-o text-dark" />;
                    }
                    hasButton = hasAvailableFromTimestamp;
                }

                const elements = roomId === dropConfirm?.roomInfoId ? [ghostElementOnDrop] : [];
                const reservations = reservationsMap.get(roomId) || [];

                for (const r of reservations) {
                    if (hideReservationStatusEnums.includes(r.statusEnum)) continue;

                    const isCondoOwner = !!r.condoUserUuid;
                    if (hideCondoReservations && isCondoOwner) continue;

                    const uninvoiced =
                        r.statusEnum === 4 &&
                        ((r.GroupInfo && r.GroupInfo.invoicedStatus !== 1) || (!r.GroupInfo && r.invoicedStatus !== 1))
                            ? translate('UNINVOICED')
                            : null;

                    const customerName = r.Customer
                        ? `${r.Customer.firstName} ${r.Customer.lastName}`
                        : r.Company
                        ? r.Company.name
                        : translate('GUEST');

                    const resReport = r.providerReservation
                        ? new ReservationController().getReservationValidationReport(r)
                        : null;
                    const overlaps = resReport?.overlaps || null;

                    elements.push({
                        id: `reservation-${r.id || 'new'}`,
                        reservation: {
                            id: r.id,
                            roomInfoId: r.roomInfoId,
                            checkInTimestamp: r.checkInTimestamp,
                            checkOutTimestamp: r.checkOutTimestamp,
                            statusEnum: r.statusEnum,
                            customerId: r.customerId,
                            companyId: r.companyId,
                            adultsNumber: r.adultsNumber,
                            childrenNumber: r.childrenNumber,
                            accommodationPriceListId: r.accommodationPriceListId,
                            openPrice: r.openPrice,
                            groupUuid: r.groupUuid,
                            providerReservation: r.providerReservation,
                        },
                        title: `${r.id || 'new'} - ${customerName}`,
                        unInvoicedTitle: uninvoiced,
                        start: new Date(r.checkInTimestamp),
                        end: new Date(r.checkOutTimestamp),
                        tooltip: (
                            <div>
                                <div className="mb-1">
                                    #{r.id || 'new'} -{' '}
                                    <b>
                                        {customerName} {r.notes && <i className="fa fa-commenting" />}
                                    </b>
                                </div>
                                <i className="fa fa-calendar-check-o" /> {moment(r.checkInTimestamp).format('lll')}{' '}
                                <br />
                                <i className="fa fa-sign-out" /> {moment(r.checkOutTimestamp).format('lll')} <br />
                                <div className="mt-3">{reservationStatusEnumsDesc[r.statusEnum]}</div>
                            </div>
                        ),
                        style: {
                            backgroundColor: overlaps?.[0]
                                ? '#fc8518'
                                : isCondoOwner
                                ? r.id
                                    ? reservationStatusColorsHEXCondo[r.statusEnum]
                                    : '#83d9e6'
                                : r.id
                                ? reservationStatusColorsHEX[r.statusEnum]
                                : '#83d9e6',
                            boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
                            borderBottom: r.id && r.providerReservation ? '2px solid #000000' : '',
                        },
                    });
                }

                return {
                    id: `room-${roomId}`,
                    title: (
                        <div className="d-flex justify-content-center align-items-center position-relative">
                            <span
                                title={translate(TaskStatusEnumText[cleaningStatus])}
                                style={{
                                    backgroundColor: cleaningStatusColor,
                                    display: 'inline-block',
                                    width: '5px',
                                    position: 'absolute',
                                    left: 0,
                                    height: '100%',
                                    color: cleaningStatus ? 'white' : 'black',
                                }}
                            />
                            <span className="pl-1 text-bold" style={{ fontSize: '14px' }}>
                                {room.name}
                            </span>
                        </div>
                    ),
                    hasButton,
                    room: { id: roomId },
                    trackButtonIconOverride,
                    elements,
                };
            });
        };

        const reservationClickHandler = (element: any) => {
            let reservation = element.reservation;

            if (reservationRoute === 'reservations') {
                unstable_batchedUpdates(() => {
                    props.toggleSelectRoomForReservation(reservation.roomInfoId);
                    props.setReservationId(reservation.id);
                    props.setReservationUuid(reservation.groupUuid);
                });
            } else {
                unstable_batchedUpdates(() => {
                    props.toggleSelectRoomForReservation(reservation.roomInfoId);
                    props.setReservationId(reservation.id);
                });
            }
        };

        const clickTrackButton = (element: any) => {
            switch (getTrackButtonMode()) {
                default:
                case 'roomMode':
                    props.toggleSelectRoomForReservation(null);
                    if (reservationRoute !== 'condoReservation') {
                        props.toggleGroupReservations(true);
                    }
                    props.toggleSelectRoomForReservation(element.room.id);
                    break;
                case 'reservationMode':
                    props.toggleSelectRoomForReservation(null);
                    props.toggleSelectRoomForReservation(element.room.id);
                    props.toggleGroupReservations(true);
                    break;
                case 'groupReservationsMode':
                    props.setReservationId(null);
                    props.setReservationUuid(null);
                    props.toggleSelectRoomForReservation(element.room.id);
                    break;
            }
        };

        const getTrackButtonMode = () => {
            let mode = 'roomMode';
            if (props.groupReservations) {
                mode = 'groupReservationsMode';
            } else if (props.showComponents.includes('timeline')) {
                if (props.availableFromTimestamp && props.availableToTimestamp) {
                    mode = 'reservationMode';
                } else {
                    mode = 'roomMode';
                }
            }
            return mode;
        };

        const onMouseDrag = (e: any) => {
            if (e.buttons === 1) {
                let ev: any = new Event('mouseDragScroll');
                ev.clientX = e.clientX;
                ev.dragOffset = dragOffsetStart - e.clientX;
                if (dragOffset !== ev.dragOffset) {
                    dragOffset = ev.dragOffset;
                    dragOffsetStart = e.clientX;
                    window.dispatchEvent(ev);
                }
            } else {
                dragOffsetStart = e.clientX;
            }
        };

        const { ghostTrack = null, basic = false, showLegend = true, showMarker = true } = props;

        const start = new Date(`${startFrom}`);
        const end = new Date(`${new Date().getFullYear() + (timelineYearsCount ? timelineYearsCount : 2)}`);

        let nowMarker = props.availableFromTimestamp ? new Date(props.availableFromTimestamp) : new Date();
        if (props.nowMarker) {
            nowMarker = props.nowMarker;
        }

        let scale = {
            start,
            end,
            zoom,
            zoomMin: MIN_ZOOM,
            zoomMax: MAX_ZOOM,
        };

        const allReservations = React.useMemo(() => {
            const newReservations = props.activeReservations?.filter((r) => !r.id);
            return props.reservations ? props.reservations.concat(newReservations || []) : newReservations || [];
        }, [props.reservations, props.activeReservations]);

        const tracks2 = React.useMemo(() => {
            return getTracksFromRoomsAndReservations(props.rooms, allReservations, hideReservationStatusEnums);
            // eslint-disable-next-line
        }, [props.rooms, allReservations, hideReservationStatusEnums, props.selectedRoomsForReservations]);

        if (ghostTrack) {
            tracks2?.[0]?.elements?.push({
                ...ghostTrack,
                id: 'ghost',
                style: {
                    backgroundColor: 'rgba(4, 190, 254, 0.1)',
                    borderRadius: '0px',
                    top: '-6px',
                    height: '40px',
                },
            });
        }

        let trackButtonIcon = null;
        switch (getTrackButtonMode()) {
            default:
            case 'roomMode':
                trackButtonIcon = <i className="fa fa-plus color-primary" style={{ fontWeight: 100 }} />;
                break;
            case 'reservationMode':
                trackButtonIcon = <i className="fa fa-plus color-primary" style={{ fontWeight: 100 }} />;
                break;
            case 'groupReservationsMode':
                trackButtonIcon = <i className="fa fa-square-o text-dark" />;
                break;
        }

        const { style = { height: `${tableContentHeight}px`, overflowY: 'auto' } } = props;

        let Legend = showLegend ? (
            <ReservationStatusLegend
                toggleHideReservationStatusEnums={toggleHideReservationStatusEnums}
                hideReservationStatusEnums={hideReservationStatusEnums}
                toggleHideCondoReservations={toggleHideCondoReservations}
                hideCondoReservations={hideCondoReservations}
            />
        ) : null;

        const onDragStart = React.useCallback((reservation: Reservation) => {
            setDragAndDropReservation(reservation);
        }, []);

        const onDrop = React.useCallback(
            async (roomInfoId: number) => {
                if (dragAndDropReservation) {
                    const newReservation: any = { ...dragAndDropReservation, roomInfoId: roomInfoId };
                    delete newReservation.Company;
                    delete newReservation.Customer;
                    delete newReservation.RoomInfo;
                    delete newReservation.GroupInfo;
                    const reservationForUpdate: ReservationModel = { ...newReservation };
                    let customerName = translate('GUEST');
                    if (dragAndDropReservation.Customer) {
                        customerName = `${dragAndDropReservation.Customer.firstName} ${dragAndDropReservation.Customer.lastName}`;
                    } else if (dragAndDropReservation.Company) {
                        customerName = dragAndDropReservation.Company.name;
                    }

                    const ctrl = new ReservationController();
                    const resReport =
                        reservationForUpdate && reservationForUpdate.providerReservation
                            ? ctrl.getReservationValidationReport(newReservation)
                            : null;
                    const overlaps = resReport?.overlaps ? resReport.overlaps : null;
                    const ghostElement = {
                        id: 'reservation-' + dragAndDropReservation.id ? dragAndDropReservation.id : '',
                        reservation: {
                            id: dragAndDropReservation.id,
                            roomInfoId: dragAndDropReservation.roomInfoId,
                            checkInTimestamp: dragAndDropReservation.checkInTimestamp,
                            checkOutTimestamp: dragAndDropReservation.checkOutTimestamp,
                            statusEnum: dragAndDropReservation.statusEnum,
                            customerId: dragAndDropReservation.customerId,
                            companyId: dragAndDropReservation.companyId,
                            adultsNumber: dragAndDropReservation.adultsNumber,
                            childrenNumber: dragAndDropReservation.childrenNumber,
                            accommodationPriceListId: dragAndDropReservation.accommodationPriceListId,
                            openPrice: dragAndDropReservation.openPrice,
                            groupUuid: dragAndDropReservation.groupUuid,
                            providerReservation: dragAndDropReservation.providerReservation,
                        },
                        title: `${
                            dragAndDropReservation.id?.toString() ? dragAndDropReservation.id.toString() : ''
                        } - ${customerName ? customerName : ''} ${''} `, //"REZERVACIJA",
                        unInvoicedTitle: '',
                        start: new Date(dragAndDropReservation.checkInTimestamp),
                        end: new Date(dragAndDropReservation.checkOutTimestamp),
                        tooltip: null,
                        style: {
                            backgroundColor: overlaps && overlaps[0] ? '#fc8518' : '#83d9e6',
                            boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
                            borderBottom:
                                dragAndDropReservation.id && dragAndDropReservation.providerReservation
                                    ? '2px solid #000000'
                                    : '',
                        },
                    };

                    setGhostElementOnDrop(ghostElement);
                    setDropConfirm(reservationForUpdate);
                }
            },
            [dragAndDropReservation]
        );

        const dropToRoom = props?.rooms?.find((room) => room.id === dropConfirm?.roomInfoId);
        const dragedFromRoom = props?.rooms?.find((room) => room.id === dragAndDropReservation?.roomInfoId);

        return (
            <div className="clear">
                <InlineModal
                    show={dropConfirm} //azurira cijene na pojedinacnom syncu unutar sobe
                    width="md"
                    header={`${translate('Change room')}`}
                    setShow={setDropConfirm}
                >
                    {dropConfirm ? (
                        <div className="text-center my-3">
                            <div>{`${translate('Move reservation from room')} ${dragedFromRoom?.name} ${translate(
                                'to room'
                            )} ${dropToRoom?.name}`}</div>
                            <br />
                            <ConfirmButtons
                                onConfirm={async () => {
                                    try {
                                        await saveOrUpdateReservation(dropConfirm);
                                    } catch (error) {
                                        console.log('ERRROOOORRRR', error);
                                    }
                                    setDropConfirm(null);
                                    setGhostElementOnDrop(null);
                                }}
                                onCancel={async () => {
                                    setDropConfirm(null);
                                    setGhostElementOnDrop(null);
                                }}
                            />
                        </div>
                    ) : null}
                </InlineModal>
                <div
                    className="clear"
                    id="timeline"
                    onClick={(e) => {
                        const _mousePosition = {
                            x: e.clientX,
                            y: e.clientY,
                        };
                        props.setMousePosition({ ..._mousePosition });
                    }}
                    onMouseMove={(e: any) => onMouseDrag(e)}
                >
                    <Timeline
                        style={style}
                        scale={scale}
                        enableSticky
                        clickElement={basic ? null : reservationClickHandler}
                        trackButtonIcon={basic ? null : trackButtonIcon}
                        clickTrackButton={basic ? null : clickTrackButton}
                        scrollToNow
                        isOpen={basic ? false : open}
                        timebar={timebar}
                        tracks={tracks2}
                        now={showMarker ? nowMarker : null}
                        onDragStart={onDragStart}
                        onDrop={onDrop}
                    />
                </div>

                {Legend ? <div className="reservation-status-checkboxes-wrapper">{Legend}</div> : null}
            </div>
        );
    },
    (prevProps: any, nextProps: any) => {
        // If ANY prop other than `rooms` changes, always re-render
        if (!isEqual(prevProps.rooms, nextProps.rooms)) return false; // Re-render only if `rooms` changed

        // Check all other props dynamically
        for (const key in prevProps) {
            if (key !== 'rooms' && prevProps[key] !== nextProps[key]) {
                return false; // Re-render if any other prop changed
            }
        }

        return true;
    }
);

export default ReservationsTimeline;
