import React, { useRef, useState } from 'react';
import translate from '@data/translations';
import { AccommodationPricelistModel, ReservationStatus, RoomInfoEditModel } from '@common/modelDefinition';
import { ReactComponent as Close } from '../../assets/custom/icons/ex.svg';
import { ReservationController, ValidationParams } from './resController';
import { getHourAndMinuteObj } from './utils';
import { getRoomPricelist } from './reservations/AssignPriceList';
import TabsWrapper from './tabsWrapper';
import ReservationLeavingGuard from './closingFormGuard';
import LoadingButton from '@components/buttons/loadingButton';
import ReservationValidationMessageList from './reservations/reservationValidationMessageList';
import OverlappingReservations from './reservations/overlappingReservations';
//@ts-ignore
import _ from 'lodash';
import useStore from '@data/state/zustand';

const calculateInitialPosition = (mousePosition: { x: number; y: number }, windowSize: number[]) => {
    const y_size =
        windowSize[0] < 1200
            ? mousePosition.y + 100
            : windowSize[1] < 820
            ? windowSize[1] - (windowSize[1] - 100)
            : windowSize[1] - (windowSize[1] - 60);
    const x_size =
        windowSize[0] < 700
            ? 0
            : windowSize[0] >= 700 && windowSize[0] < 1200
            ? mousePosition.x - 15
            : windowSize[0] >= 1200 && windowSize[0] < 1450
            ? windowSize[0] - (windowSize[0] - 550)
            : windowSize[0] >= 1450 && windowSize[0] < 1650
            ? windowSize[0] - (windowSize[0] - 700)
            : windowSize[0] - (windowSize[0] - 700);

    return { x: x_size, y: y_size };
};

interface ReservationFormProps {
    history: any;
    mousePosition: any;
    selectedRoomsForReservations: number[] | null;
    toggleSelectRoomForReservation: Function;
    reservationId?: number | null;
    groupUuid?: string | null;
    availableFromTimestamp?: any;
    availableToTimestamp?: any;
    redirectPath?: string;
    invoiceGroupUuid?: string | null;
    reloadParentData?: Function;
    showReservationDetails?: Function;
}

const reservationValidationParams: ValidationParams = {
    checkInTimestamp: true,
    accommodationPriceListIdOrOpenPrice: true,
    roomInfoId: true,
    groupReservationHolder: true,
    reservationHolder: true,
};

const ReservationForm = React.memo((props:ReservationFormProps) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const { x, y } = calculateInitialPosition(props.mousePosition, windowSize.current);
    const [position, setPosition] = useState({ x, y }); // Set initial position directly

    useWhyDidYouUpdate('ReservationForm', props);

function useWhyDidYouUpdate(name:any, props:any) {
    const previousProps:any = React.useRef();

    React.useEffect(() => {
        if (previousProps.current) {
            const allKeys = Object.keys({ ...previousProps.current, ...props });
            const changes:any = {};
            allKeys.forEach((key) => {
                if (previousProps.current[key] !== props[key]) {
                    changes[key] = {
                        from: previousProps.current[key],
                        to: props[key],
                    };
                }
            });
        }

        previousProps.current = props;
    });
}

    const {
        history,
        selectedRoomsForReservations,
        toggleSelectRoomForReservation,
        reservationId,
        redirectPath,
        invoiceGroupUuid,
        reloadParentData,
        showReservationDetails,
        groupUuid,
    } = props;

    const otherSettings: any = useStore((appState: any) => appState.otherSettings);
    const accommodationPriceLists = useStore(appstate => appstate.model.AccommodationPriceList);

    const acp = React.useMemo(() => {
        return accommodationPriceLists.filter((acc: any) => !acc.condoPricelist && acc.isActive && !acc.isDeleted);
    }, [accommodationPriceLists]);

    useStore((appState: any) => appState.state.reservationForm2);

    const isPending: boolean = useStore((appState: any) => appState.state.reservationForm2.isPending);
    const roomInfos: RoomInfoEditModel[] = useStore((appState: any) => appState.model.RoomInfo);

    const filteredRooms = React.useMemo(() => {
        return roomInfos.filter((room) => {
            // Add filtering logic here
            return true;
        });
    }, [roomInfos]);

    const [modalVisible, setModalVisible] = useState(false);
    const [message, setMessage] = useState(null as string | null);
    const [dragStartData, setDragStartData] = useState({
        currentX: x,
        currentY: y,
        currentMouseX: x,
        currentMouseY: y,
    });
    const ctrl = new ReservationController();

    const reservation = ctrl.getActiveReservation();
    const groupRes = ctrl.getGroupReservation();


    const handleDragEnd = React.useCallback((event: any) => {
        const draggableContainer: any = document.querySelector('.draggable-container');
        const yToSet = event.clientY < draggableContainer.offsetHeight - draggableContainer.offsetHeight * 0.9 ? 0 : event.clientY + (dragStartData.currentY - dragStartData.currentMouseY)
        const xToSet = event.clientX < draggableContainer.offsetWidth - draggableContainer.offsetWidth * 0.9 ? 0 : event.clientX + (dragStartData.currentX - dragStartData.currentMouseX)
        setPosition({x:xToSet, y:yToSet})
    }, [dragStartData]);
    
    const handleMouseDown = React.useCallback((e: any) => {
        setDragStartData({
            currentX: position.x,
            currentY: position.y,
            currentMouseX: e.clientX,
            currentMouseY: e.clientY,
        });
    }, [position.x, position.y]);

    React.useEffect(() => {
        const ctrl2 = new ReservationController();
        if (reservationId) {
            ctrl2.loadReservation(reservationId);
        } else if (groupUuid) {
            ctrl2.loadGroup(groupUuid);
        } else {
            const { checkInTimestamp, checkOutTimestamp } = getCheckInAndCheckoutTimestamps(
                otherSettings,
                props.availableFromTimestamp,
                props.availableToTimestamp
            );
            if (selectedRoomsForReservations && selectedRoomsForReservations?.length > 0) {
                selectedRoomsForReservations.forEach((roomId: number, index: number) => {
                    const room = filteredRooms?.find((r: RoomInfoEditModel) => r.id === roomId);
                    let targetPriceList: AccommodationPricelistModel | null | undefined = null;
                    if (room && acp) {
                        const targetPricelists: any[] = getRoomPricelist(room, acp);
                        if (targetPricelists?.length === 1) {
                            targetPriceList = acp?.find(
                                (a: AccommodationPricelistModel) =>
                                    targetPricelists[0].id && a.id === targetPricelists[0].id
                            );
                        }
                    }
                    const accommodationPriceListId = targetPriceList?.id ? targetPriceList.id : null;
                    const board = targetPriceList?.defaultBoard ? targetPriceList.defaultBoard : 'bb';
                    const clearPrevious = index === 0;
                    ctrl2.addReservation(
                        clearPrevious,
                        roomId,
                        checkInTimestamp,
                        checkOutTimestamp,
                        1,
                        accommodationPriceListId,
                        board
                    );
                    if (invoiceGroupUuid) {
                        //groupUuid ovo se ne moze dogodit ...
                        ctrl2.addExistingGroup(invoiceGroupUuid);
                    }
                });
            }
        }

        return () => {
            ctrl2.clearAll(); //cleanup on unmount
        };
        // eslint-disable-next-line
    }, [
        reservationId,
        groupUuid,
        selectedRoomsForReservations,
        props.availableFromTimestamp,
        props.availableToTimestamp,
    ]);


    const checkRouteLeaving = () => {
        const ctrl = new ReservationController();
        const invalidVisaIds = ctrl.getCustomersWithInvalidVisaIds();
        const res = ctrl.getActiveReservation();

        let message = null;
        if (res?.id && ((isPending && !_.isEmpty(invalidVisaIds)) || (isPending && _.isEmpty(invalidVisaIds)))) {
            message = 'leave_guard_note';
        }
        if (
            res?.id &&
            (res?.statusEnum === ReservationStatus.confirmed || res?.statusEnum === ReservationStatus.checkedIn) &&
            !isPending &&
            !_.isEmpty(invalidVisaIds)
        ) {
            message = 'leave_guard_note_visa';
        }

        if (message) {
            setModalVisible(true);
            setMessage(message);
        } else {
            toggleSelectRoomForReservation(null);
        }
    };


    const handleClose = React.useCallback(() => {
        checkRouteLeaving();
    }, [checkRouteLeaving]);

    if (!reservation) {
        return null;
    }

    return (
        <div className="reservation-form border shadow-sm draggable-container" style={{ top: position.y, left: position.x }}>
            <ReservationLeavingGuard
                message={message}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                toggleSelectRoomForReservation={toggleSelectRoomForReservation}
            />
            <div
                draggable
                style={{ cursor: 'grab' }}
                onDragEnd={handleDragEnd}
                onDragStart={handleMouseDown}
                className="reservation-form-header"
            >
                <div className="reservation-form-title">
                    {groupRes
                        ? translate('Group reservation') + ' - ' + groupRes?.groupName
                        : reservation?.id
                        ? translate('Reservation') + ' #' + reservation?.id
                        : selectedRoomsForReservations && selectedRoomsForReservations?.length > 1
                        ? translate('Group reservation')
                        : translate('New reservation')}
                </div>
                <div className="close">
                    <Close style={{ cursor: 'pointer' }} onClick={handleClose} />
                </div>
            </div>

            <TabsWrapper history={history} showReservationDetails={showReservationDetails} />

            <div className="reservation-form-foother mb-8p">
                <div className="">
                    <ReservationValidationMessageList validationParams={reservationValidationParams}>
                        <div className="display-flex justify-content-end ml-auto">
                            <LoadingButton
                                className="button-primary mr-4p "
                                disabled={!isPending}
                                onClick={async () => {
                                    await ctrl.save(
                                        groupUuid ||
                                            (selectedRoomsForReservations &&
                                                selectedRoomsForReservations?.length > 1) ||
                                            reservation.groupUuid ||
                                            invoiceGroupUuid
                                            ? true
                                            : false //isGroupReservation
                                    );
                                    if (reloadParentData) {
                                        reloadParentData();
                                    }
                                    if (redirectPath) {
                                        history.push(redirectPath);
                                    }
                                }}
                            >
                                {reservation?.id ? translate('Update') : translate('New reservation')}
                            </LoadingButton>
                        </div>
                    </ReservationValidationMessageList>
                </div>
                <div className="">
                    {' '}
                    <OverlappingReservations reservation={reservation} />
                </div>
            </div>
        </div>
    );
})

export const getCheckInAndCheckoutTimestamps = (otherSettings: any, checkInDate?: Date, checkOutDate?: Date) => {
    const { checkInTime = '12:00', checkOutTime = '12:00' } = otherSettings;
    const checkInHourAndMinute = getHourAndMinuteObj(checkInTime);
    const checkOutHourAndMinute = getHourAndMinuteObj(checkOutTime);

    const defaultCheckIn = new Date(
        new Date().setHours(checkInHourAndMinute.hour, checkInHourAndMinute.minute)
    ).getTime();

    const defaultCheckOut =
        new Date(new Date().setHours(checkOutHourAndMinute.hour, checkOutHourAndMinute.minute)).getTime() +
        24 * 60 * 60 * 1000;

    const checkInTimestamp = checkInDate
        ? new Date(new Date(checkInDate).setHours(checkInHourAndMinute.hour, checkInHourAndMinute.minute)).getTime()
        : defaultCheckIn;

    const checkOutTimestamp = checkOutDate
        ? new Date(new Date(checkOutDate).setHours(checkOutHourAndMinute.hour, checkOutHourAndMinute.minute)).getTime()
        : defaultCheckOut;

    return { checkInTimestamp, checkOutTimestamp };
};

export default ReservationForm;