import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Sidebar from './sidebar';
import FeatureFlag from '@components/FeatureFlag';
import TimelineView from './reservationsTimeline';
import HwRoomView from './hwRoomView';
import Toolbar from './toolbar';
import ToolbarReservationMode from './toolbarReservationMode';
import SidebarToolbar from './sidebarToolbar';
import ReservationForm from '../newReservations';
import { ReservationController } from '../newReservations/resController';
import { RoomInfoEditModel } from '@common/modelDefinition';
//@ts-ignore
import _ from 'lodash';
import ReservationDetails from '../newReservations/reservations/reservationDetails';
import useStore from '@data/state/zustand';
import { getGroupedTasksByRoomInfo } from '@pages/householdNew/controller';
import { unstable_batchedUpdates } from 'react-dom';
interface DashboardProps {
    location: any;
    history: any;
    match: any;
}

interface SidebarWrapperProps {
    children: any;
}

const SidebarWrapper = React.memo((props: SidebarWrapperProps) => {
    const [pageHeight, setPageHeight] = useState(300);

    useEffect(() => {
        const getTableHeight = () => {
            const el = document.getElementById('timeline');
            const mainAppDiv = document.getElementById('main-app-div');

            if (el && mainAppDiv) {
                const positionOfTableHeaderElement = getPos(el);
                const heightOfTheSpaceFromTableHeaderToDocumentFooter =
                    mainAppDiv.scrollHeight - positionOfTableHeaderElement.y;
                const tableContentHeight = heightOfTheSpaceFromTableHeaderToDocumentFooter - 50 - 23;
                return tableContentHeight;
            }
            return 300; // Default height
        };

        setPageHeight(getTableHeight());
        // eslint-disable-next-line
    }, []);

    function getPos(el: any) {
        // yay readability
        for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
        return { x: lx, y: ly };
    }

    return (
        <div
            id="sidebar"
            style={{
                width: '20%',
                display: 'inline-block',
                height: `${pageHeight}px`,
                overflow: 'auto',
                overflowX: 'hidden',
            }}
        >
            {props.children}
        </div>
    );
});

const filterRoomsByAnyAttribute = (rooms: RoomInfoEditModel[], attributesFilter: number[]) => {
    return rooms.filter((r: any) => {
        if (attributesFilter.length > 0) {
            const roomAttributes = r.roomAttributes ? JSON.parse(r.roomAttributes) : [];
            return attributesFilter.some((att: number) => roomAttributes.includes(att));
        }
        return true;
    });
};

const filterRoomsByCleaningStatuses = (
    rooms: RoomInfoEditModel[],
    cleaningStatusesFilter: any,
    groupedTaskItems: any
) => {
    return cleaningStatusesFilter.length > 0
        ? rooms.filter((r: any) => {
              const targetTaskItems = groupedTaskItems[r.id];
              const statusObj = targetTaskItems && targetTaskItems.length > 0 ? targetTaskItems[0] : null;
              const cleaningStatus = statusObj?.taskStatus || 0;
              return cleaningStatus === cleaningStatusesFilter[0];
          })
        : rooms;
};

const parseRoomInfo = (roomInfoDataSet: RoomInfoEditModel[]) => {
    return roomInfoDataSet.map((r: RoomInfoEditModel) => {
        const tmp = { ...r }; // Use spread operator instead of
        if (r.SRCSettings) tmp.SRCSettings = JSON.parse(r.SRCSettings);
        if (r.params) tmp.params = JSON.parse(r.params);
        //@ts-ignore
        tmp.tags = r.tags && typeof r.tags === 'string' ? r.tags.split(';').map((s) => s.trim()) : [];
        return tmp;
    });
};

const Dashboard = React.memo((props: DashboardProps) => {
    const [, setTimelineLoaded] = useState(false);
    const [availableFromTimestamp, setAvailableFromTimestamp] = useState(Date.now());
    const uiStates = useStore((appState: any) => appState.uiStates.dashboard);
    const [availableToTimestamp, setAvailableToTimestamp] = useState<number | null>(null);
    const [stayInDays, setStayInDays] = useState<number | null>(null);
    const [groupReservations, setGroupReservations] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermRooms, setSearchTermRooms] = useState('');
    const [numberOfBeds, setNumberOfBeds] = useState<string | number>('');
    const [numberOfSpareBeds, setNumberOfSpareBeds] = useState<string | number>('');
    const [tagsFilter, setTagsFilter] = useState<string[]>([]);
    const [filterOutRoomsById, setFilterOutRoomsById] = useState<number[]>([]);
    const [selectedRoomsForReservations, setSelectedRoomsForReservations] = useState<number[]>([]);
    const [attributesFilter, setAttributesFilter] = useState<number[]>([]);
    const [attributesFilteringType, setAttributesFilteringType] = useState(true);
    const [showOccupiedRooms, setShowOccupiedRooms] = useState(true);
    const [cleaningStatusesFilter, setCleaningStatusesFilter] = useState<number[]>([]);
    const [showReservationsList, setShowReservationsList] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [reservationId, setReservationId] = useState<number | null>(null);
    const [reservationUuid, setReservationUuid] = useState<string | null>(null);
    const [showReservationDetails, setShowReservationDetails] = useState<number | null>(null);

    let form2 = useStore((appState) => appState.state.reservationForm2.reservations); // hak veliki
    const memoizedForm2 = useMemo(() => form2, [form2]);

    const roomsByTags = useStore.getState().monkeys.parsedAndTagedRoomInfo();
    const roomInfoDataSet = useStore((state) => state.model.RoomInfo);
    const parsedRoomInfo = useMemo(() => parseRoomInfo(roomInfoDataSet), [roomInfoDataSet]);

    const taskManagementItems = useStore((state) => state.model.TaskManagementItem);
    const cleaningTaskItems = useMemo(
        () => taskManagementItems.filter((t: any) => t.taskGroup === 'cleaningTask' && !t.parentId),
        [taskManagementItems]
    );
    const sortedCleaningTaskItems = useMemo(
        () => cleaningTaskItems.sort((a: any, b: any) => a.timestamp - b.timestamp),
        [cleaningTaskItems]
    );
    const groupedTaskItems = useMemo(
        () => getGroupedTasksByRoomInfo(sortedCleaningTaskItems),
        [sortedCleaningTaskItems]
    );

    const ctrl = useMemo(() => new ReservationController(), []);
    const _activeReservations = useMemo(
        () => ctrl.getReservations(),
        // eslint-disable-next-line
        [ctrl, memoizedForm2]
    );
    const reservations_ = useStore((state) => state.model.Reservation);

    const roomsMap = useMemo(() => {
        const map: { [key: number]: RoomInfoEditModel } = {};
        parsedRoomInfo.forEach((r: any) => (map[r.id] = r));
        return map;
    }, [parsedRoomInfo]);

    const filteredRoomsByTags = useMemo(() => {
        return tagsFilter.length > 0
            ? parsedRoomInfo.filter((r: any) => r.tags && tagsFilter.every((t) => r.tags.includes(t)))
            : parsedRoomInfo;
    }, [parsedRoomInfo, tagsFilter]);

    const filteredRoomsByCleaningStatus = useMemo(() => {
        return filterRoomsByCleaningStatuses(filteredRoomsByTags, cleaningStatusesFilter, groupedTaskItems);
    }, [filteredRoomsByTags, cleaningStatusesFilter, groupedTaskItems]);

    const filteredRoomsByName = useMemo(() => {
        return filteredRoomsByCleaningStatus.filter((r) =>
            r.name.toLowerCase().includes(searchTermRooms.toLowerCase())
        );
    }, [filteredRoomsByCleaningStatus, searchTermRooms]);

    const filteredRoomsBySearchTerm = useMemo(() => {
        return filteredRoomsByName.filter((r) => r.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [filteredRoomsByName, searchTerm]);

    const filteredRoomsByBeds = useMemo(() => {
        return numberOfBeds
            ? filteredRoomsBySearchTerm.filter((r) => r.bedCount === Number(numberOfBeds))
            : filteredRoomsBySearchTerm;
    }, [filteredRoomsBySearchTerm, numberOfBeds]);

    const filteredRoomsBySpareBeds = useMemo(() => {
        return numberOfSpareBeds
            ? filteredRoomsByBeds.filter((r) => r.spareBedCount === Number(numberOfSpareBeds))
            : filteredRoomsByBeds;
    }, [filteredRoomsByBeds, numberOfSpareBeds]);

    const filteredRoomsByOccupancy = useMemo(() => {
        return filterOutRoomsById.length > 0 && !showOccupiedRooms
            ? filteredRoomsBySpareBeds.filter((r) => !filterOutRoomsById.includes(r.id))
            : filteredRoomsBySpareBeds;
    }, [filteredRoomsBySpareBeds, filterOutRoomsById, showOccupiedRooms]);

    const filteredRoomsByAttributes = useMemo(() => {
        return attributesFilteringType
            ? filterRoomsByAnyAttribute(filteredRoomsByOccupancy, attributesFilter)
            : filterRoomsByAnyAttribute(filteredRoomsByOccupancy, attributesFilter);
    }, [filteredRoomsByOccupancy, attributesFilteringType, attributesFilter]);

    const filteredRoomsBySearchTermExt = useMemo(() => {
        let filteredRooms = [...filteredRoomsByAttributes];
        let cardNumberSearch = parseInt(searchTerm, 10);
        if (!_.isNaN(cardNumberSearch) && _.isNumber(cardNumberSearch)) {
            let reservations = reservations_?.filter((r: any) => {
                return (
                    (r.guest1CodeCopy === cardNumberSearch ||
                        r.guest2CodeCopy === cardNumberSearch ||
                        r.guest3CodeCopy === cardNumberSearch) &&
                    Number(r.statusEnum) === 2
                ); //checkedIn
            });
            if (reservations.length > 0) {
                filteredRooms = []; //RESETING filteredRooms
            }
            reservations.forEach((reservation: any) => {
                let room = parsedRoomInfo.find((r: RoomInfoEditModel) => r.id === reservation.roomInfoId);
                if (room) {
                    filteredRooms.push(room);
                }
            });
        } else if (searchTerm) {
            const filteredRoomMap: any = {};
            for (const r of filteredRooms) {
                filteredRoomMap[r.id] = r.id;
            }
            reservations_ &&
                reservations_.forEach((res: any) => {
                    const guest = res.Customer;
                    const guestNameFirst = guest ? `${guest.firstName} ${guest.lastName}` : '';
                    const guestSurnameFirst = guest ? `${guest.lastName} ${guest.firstName}` : '';
                    if (
                        guest &&
                        (guestNameFirst.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1 ||
                            guestSurnameFirst.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1)
                    ) {
                        if (!filteredRoomMap[res.roomInfoId]) {
                            filteredRoomMap[res.roomInfoId] = roomsMap[res.roomInfoId];
                            filteredRooms.push(roomsMap[res.roomInfoId]);
                        }
                    }
                    if (
                        res.Company &&
                        res.Company.name &&
                        res.Company.name.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1
                    ) {
                        if (!filteredRoomMap[res.roomInfoId]) {
                            filteredRoomMap[res.roomInfoId] = roomsMap[res.roomInfoId];
                            filteredRooms.push(roomsMap[res.roomInfoId]);
                        }
                    }
                });
        }
        return filteredRooms;
        // eslint-disable-next-line
    }, [searchTerm, filteredRoomsByAttributes]);

    const filteredRooms = useMemo(() => {
        return _.orderBy(filteredRoomsBySearchTermExt, ['name'], ['asc']);
    }, [filteredRoomsBySearchTermExt]);

    const memoizedRooms = useMemo(
        () => filteredRooms.map((room: any) => ({ id: room.id, name: room.name })),
        [filteredRooms]
    );

    const toggleSelectRoomForReservation = useCallback(
        (roomInfoId: number | null) => {
            if (roomInfoId === null) {
                setSelectedRoomsForReservations([]);
                setGroupReservations(false);
                setReservationUuid(null);
                setReservationId(null);
            } else {
                if (availableToTimestamp === null) {
                    setAvailableToTimestamp(
                        availableFromTimestamp
                            ? new Date(availableFromTimestamp + 86400000).getTime()
                            : new Date(Date.now() + 86400000).getTime()
                    );
                }
                setSelectedRoomsForReservations((prev) =>
                    prev.includes(roomInfoId) ? prev.filter((id) => id !== roomInfoId) : [...prev, roomInfoId]
                );
            }
        },
        [availableFromTimestamp, availableToTimestamp]
    );

    const toggleGroupReservations = useCallback((forcedValue?: boolean) => {
        setGroupReservations((prev) => (forcedValue !== undefined ? forcedValue : !prev));
    }, []);

    const onLoadedComponent = useCallback(() => {
        setTimelineLoaded(true);
    }, []);

    const state = {
        availableFromTimestamp,
        availableToTimestamp,
        stayInDays,
        groupReservations,
        searchTerm,
        numberOfBeds,
        numberOfSpareBeds,
        tagsFilter,
        filterOutRoomsById,
        selectedRoomsForReservations,
        attributesFilter,
        attributesFilteringType,
        showOccupiedRooms,
        cleaningStatusesFilter,
    };

    const clearToolbar = useCallback(() => {
        unstable_batchedUpdates(() => {
            setGroupReservations(false);
            setNumberOfBeds('');
            setNumberOfSpareBeds('');
            toggleSelectRoomForReservation(null);
        });
        // eslint-disable-next-line
    }, []);

    const _setNumberOfBeds = useCallback((e: any) => {
        if (e === '') {
            setNumberOfBeds('');
        } else if (e.target.value === '') {
            setNumberOfBeds('');
        } else {
            if (e.target.value === '0') {
                setNumberOfBeds('');
            } else {
                setNumberOfBeds(Number(e.target.value));
            }
        }
    }, []);

    const _setNumberOfSpareBeds = useCallback((e: any) => {
        if (e === '') {
            setNumberOfSpareBeds('');
        } else if (e.target.value === '') {
            setNumberOfSpareBeds('');
        } else {
            if (e.target.value === '0') {
                setNumberOfSpareBeds('');
            } else {
                setNumberOfSpareBeds(Number(e.target.value));
            }
        }
    }, []);

    const setAvailableFromAndToTimestamps = useCallback(
        (availableFromTimestamp: number, _availableToTimestamp: number, stayInDays: number) => {
            const availableToTimestamp_ = _availableToTimestamp - 8 * 60 * 60 * 1000; //add some tollerance on filtering since this will always be at 12 o clock (noon)
            setAvailableFromTimestamp(availableFromTimestamp);
            setAvailableToTimestamp(_availableToTimestamp === null ? _availableToTimestamp : availableToTimestamp_);
            setStayInDays(stayInDays);
        },
        []
    );

    const toolbarReservationModeProps = useMemo(
        () => ({
            toggleSelectRoomForReservation,
            toggleGroupReservations,
            setNumberOfBeds: _setNumberOfBeds,
            setNumberOfSpareBeds: _setNumberOfSpareBeds,
            setHideRoomByIdFilter: (array: number[]) => setFilterOutRoomsById([...array]),
            setAvailableFromAndToTimestamps,
            toogleOccupiedRooms: () => setShowOccupiedRooms(!showOccupiedRooms),
            setShowReservationsList,
            clearToolbar,
            showReservationsList,
            ...state,
            reservations: reservations_,
        }),
        [
            toggleSelectRoomForReservation,
            toggleGroupReservations,
            _setNumberOfBeds,
            _setNumberOfSpareBeds,
            setFilterOutRoomsById,
            setAvailableFromAndToTimestamps,
            showOccupiedRooms,
            setShowReservationsList,
            clearToolbar,
            showReservationsList,
            state,
            reservations_,
        ]
    );

    const reservationForm =
        selectedRoomsForReservations?.length > 0 && !showReservationDetails ? (
            <ReservationForm
                history={props.history}
                mousePosition={mousePosition}
                selectedRoomsForReservations={selectedRoomsForReservations}
                toggleSelectRoomForReservation={toggleSelectRoomForReservation}
                reservationId={reservationId}
                groupUuid={reservationUuid}
                availableFromTimestamp={availableFromTimestamp}
                availableToTimestamp={availableToTimestamp}
                showReservationDetails={(resId: number) => {
                    setShowReservationDetails(resId);
                    setReservationId(resId);
                }}
            />
        ) : null;

    const sidebarProps = useMemo(
        () => ({
            // ...props,
            searchTerm,
            rooms: filteredRooms,
            availableFromTimestamp,
            availableToTimestamp,
            toggleSelectRoomForReservation,
            setReservationId,
            setReservationUuid,
            reservations: reservations_,
        }),
        [
            props,
            searchTerm,
            filteredRooms,
            availableFromTimestamp,
            availableToTimestamp,
            toggleSelectRoomForReservation,
            setReservationId,
            setReservationUuid,
            reservations_,
        ]
    );

    const MemoizedSidebarToolbar = React.memo(SidebarToolbar);
    const sidebarToolbarProps = useMemo(
        () => ({
            setSearchTerm,
            showComponents: uiStates.showComponents,
        }),
        [setSearchTerm, uiStates.showComponents]
    );

    const sidebarChildren = useMemo(
        () => <MemoizedSidebarToolbar {...sidebarToolbarProps} />,
        // eslint-disable-next-line
        [sidebarToolbarProps]
    );
    
    return (
        <div className="mobile-page">
            {reservationForm}
            {selectedRoomsForReservations?.length > 0 && showReservationDetails ? (
                <ReservationDetails
                    history={props.history}
                    mousePosition={{ x: 0, y: 0 }}
                    reservationId={showReservationDetails}
                    setShowDetails={() => setShowReservationDetails(null)}
                    setEditReservation={() => setShowReservationDetails(null)}
                    setPrintPreview={() => {}}
                />
            ) : null}

            {props.history.location.search === '?reservations' ||
            props.history.location.search === '' ||
            props.history.location.search === '?rooms' ? (
                <div className="hiddenn-on-mobile d-print-none">
                    <Toolbar
                        history={props.history}
                        {...{
                            availableFromTimestamp,
                            availableToTimestamp,
                            stayInDays,
                            groupReservations,
                            searchTerm,
                            numberOfBeds,
                            numberOfSpareBeds,
                            tagsFilter,
                            filterOutRoomsById,
                            selectedRoomsForReservations,
                            attributesFilter,
                            attributesFilteringType,
                            showOccupiedRooms,
                            cleaningStatusesFilter,
                        }}
                        toggleTagFilter={(tag: string | null) =>
                            setTagsFilter((prev) =>
                                tag === null ? [] : prev.includes(tag) ? prev.filter((t) => t !== tag) : [tag]
                            )
                        }
                        handleAttributesSelect={(values: any) =>
                            setAttributesFilter(values ? values.map((v: any) => v.value) : [])
                        }
                        setAttributesFilteringType={setAttributesFilteringType}
                        setSearchTerm={(e: any) => setSearchTerm(e.target.value)}
                        setSearchTermRooms={(e: any) => setSearchTermRooms(e.target.value)}
                        toggleCleaningStatusesFilter={(clStatus: number | null) =>
                            setCleaningStatusesFilter((prev) =>
                                clStatus === null
                                    ? []
                                    : prev.includes(clStatus)
                                    ? prev.filter((t) => t !== clStatus)
                                    : [clStatus]
                            )
                        }
                        roomsByTags={roomsByTags}
                    />
                </div>
            ) : null}

            <div className="unselectable px-0 mx-0">
                <div className="scrollbar-custom">
                    {uiStates.showComponents?.includes('timeline') ? (
                        <React.Fragment>
                            <FeatureFlag flag="reservationsTimelineDashboard">
                                <div className="hiddenn-on-mobile  d-print-none">
                                    <ToolbarReservationMode {...toolbarReservationModeProps} />
                                </div>
                            </FeatureFlag>
                        </React.Fragment>
                    ) : null}

                    {uiStates.showComponents?.includes('tiles') ? (
                        <div className="clear w-100" style={{ overflowX: 'auto', paddingBottom: '20px' }}>
                            <HwRoomView {...props} rooms={filteredRooms} reservations={reservations_} />
                        </div>
                    ) : null}

                    {uiStates.showComponents?.includes('timeline') && (
                        <div
                            className="mb-1 clear d-print-none"
                            style={{ position: 'relative', display: showReservationsList ? 'block' : 'flex' }}
                        >
                            <div id={!showReservationsList ? 'timeline-view' : 'wiev-enilemite'}>
                                <TimelineView
                                    selectedRoomsForReservations={selectedRoomsForReservations}
                                    showComponents={uiStates.showComponents}
                                    availableFromTimestamp={availableFromTimestamp}
                                    availableToTimestamp={availableToTimestamp}
                                    reservations={reservations_}
                                    toggleSelectRoomForReservation={toggleSelectRoomForReservation}
                                    groupReservations={groupReservations}
                                    rooms={memoizedRooms}
                                    setMousePosition={setMousePosition}
                                    toggleGroupReservations={toggleGroupReservations}
                                    setReservationId={setReservationId}
                                    setReservationUuid={setReservationUuid}
                                    activeReservations={_activeReservations}
                                    groupedCleaningTasksByRoom={groupedTaskItems}
                                    onLoaded={onLoadedComponent}
                                />
                            </div>

                            {!showReservationsList && (
                                <SidebarWrapper>
                                    <Sidebar {...sidebarProps}>{sidebarChildren}</Sidebar>
                                </SidebarWrapper>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default Dashboard;
