import React, { useEffect, useState } from 'react';
//@ts-ignore
import _ from 'lodash';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { reservationEnums } from '../../data/reservationStatusEnums';
import FeatureFlag from '../../components/FeatureFlag';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import { ReservationModel } from '@common/modelDefinition';
import translate from '@data/translations';
import { ReactComponent as Tick } from '../../assets/custom/icons/tick.svg';
import { ReactComponent as Close } from '../../assets/custom/icons/ex.svg';
import useStore from '@data/state/zustand';
import { unstable_batchedUpdates } from 'react-dom';

interface FromToFilterProps {
    filterOutRoomsById: any[];
    setHideRoomByIdFilter: Function;
    reservations: ReservationModel[];
    setAvailableFromAndToTimestamps: Function;
    toggleGroupReservations: Function;
    toggleSelectRoomForReservation: Function;
    setNumberOfBeds: Function;
    setNumberOfSpareBeds: Function;
    groupReservations: boolean;
    numberOfBeds: number | string;
    numberOfSpareBeds: number | string;
    showReservationsList: boolean;
    setShowReservationsList: Function;
    toogleOccupiedRooms: Function;
    showOccupiedRooms: boolean;
    availableFromTimestamp: number;
    availableToTimestamp: number | null;
    clearToolbar: Function;
}

function FromToFilter(props: FromToFilterProps) {
    const [stayInDays, setStayInDays] = useState(1 as number);
    const [availableFrom2 /* setAvailableFrom2 */] = useState('' as string);
    const [availabilityFiltersShown, setAvailabilityFiltersShown] = React.useState(false as boolean);
    const availableFrom = props.availableFromTimestamp;
    const availableTo = props.availableToTimestamp;

    const otherSettings = useStore((appState: any) => appState.otherSettings);

    useEffect(() => {
        const loadData = async () => {
            if (availableTo !== null) {
                calculateAvailableRoomsInRange();
            } else {
                if (props.filterOutRoomsById.length > 0) {
                    props.setHideRoomByIdFilter([]);
                }
                //
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [availableFrom, availableTo]);

    const calculateAvailableRoomsInRange = () => {
        if (props.reservations) {
            let roomIdWithReservationOverlapping: number[] = [];
            props.reservations?.forEach((r) => {
                let b = moment(availableFrom).toDate();
                b.setHours(22, 59, 59);
                const availableFrom_adjusted = b.getTime();

                let a = moment(availableTo).add(-1, 'd').toDate();
                a.setHours(22, 59, 59);
                const availableTo_adjusted = a.getTime();

                if (r.checkInTimestamp > availableFrom_adjusted && r.checkInTimestamp < availableTo_adjusted) {
                    //test checkin overlap
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }
                if (r.checkOutTimestamp > availableFrom_adjusted && r.checkOutTimestamp < availableTo_adjusted) {
                    //test checkout overlap
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }
                if (r.checkInTimestamp === availableFrom_adjusted && r.checkOutTimestamp === availableTo_adjusted) {
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }

                if (r.checkInTimestamp < availableFrom_adjusted && r.checkOutTimestamp > availableFrom_adjusted) {
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }
            });
            roomIdWithReservationOverlapping = _.uniq(roomIdWithReservationOverlapping);
            let hasChangedFromLastRender =
                props.filterOutRoomsById.join(',') !== roomIdWithReservationOverlapping.join(',');
            if (hasChangedFromLastRender) {
                if (props.setHideRoomByIdFilter) {
                    props.setHideRoomByIdFilter(roomIdWithReservationOverlapping);
                }
            }
        }
    };

    const getHourAndMinuteObj = (time: any) => {
        let newTime = time ? time.split(':') : [12, 0]; //add default time if setting does not exist
        let hour = Number(newTime[0]);
        let minute = Number(newTime[1]);
        return { hour, minute };
    };

    const setInputs = (availableFrom: any, availableTo: any, stayInDays: number) => {
        const { checkInTime = '14:00', checkOutTime = '10:00' } = otherSettings;
        let checkInHourAndMinute = getHourAndMinuteObj(checkInTime);
        let checkOutHourAndMinute = getHourAndMinuteObj(checkOutTime);
        const availableFromAdjusted = new Date(
            new Date(availableFrom).setHours(checkInHourAndMinute.hour, checkInHourAndMinute.minute, 0, 0)
        ).getTime();

        if (stayInDays === 0) {
            setStayInDays(0);
            // setAvailableTo(null);
            props.setAvailableFromAndToTimestamps(availableFromAdjusted, null, null);
        } else {
            const availableToAdjusted = new Date(
                new Date(availableTo).setHours(checkOutHourAndMinute.hour, checkOutHourAndMinute.minute, 0, 0)
            ).getTime();

            // setAvailableFrom(availableFromAdjusted);
            setStayInDays(stayInDays);
            // setAvailableTo(availableToAdjusted);
            props.setAvailableFromAndToTimestamps(availableFromAdjusted, availableToAdjusted, stayInDays);
        }
    };

    const handleAvailableFromDatePick = (pick: any) => {
        try {
            if (stayInDays === 0) {
                let availableTo = pick.getTime() + 24 * 60 * 60 * 1000;
                setInputs(pick.getTime(), availableTo, 1);
            } else {
                let availableTo = pick.getTime() + 24 * 60 * 60 * 1000 * Number(stayInDays);
                setInputs(pick.getTime(), availableTo, stayInDays);
            }
        } catch (err) {
            console.warn(err);
        }
    };

    const handleAvailableToDatePick = (pick: any) => {
        try {
            let stayInDays = Math.ceil((pick.getTime() - availableFrom) / (24 * 60 * 60 * 1000));
            setInputs(availableFrom, pick.getTime(), stayInDays);
            props.toggleGroupReservations(true);
        } catch (err) {
            console.warn(err);
        }
    };

    const changeStayInDays = (e: any) => {
        let stayInDays = e.target.value;
        let availableTo = null;
        if (e.target.value !== '') {
            stayInDays = Number(e.target.value);
            availableTo = availableFrom + stayInDays * 24 * 60 * 60 * 1000;
        }
        setInputs(availableFrom, availableTo, stayInDays);
    };

    const clearHandler = () => {
        setInputs(Date.now(), null, 0);

                    unstable_batchedUpdates(() => {
        
        props.clearToolbar()
    })};
    

    let dayPicker_availableToStyles: any = { width: '110px' };
    let dayPicker_availableToStyles_wide: any = { width: '300px' };
    if (props.groupReservations && availableTo === null) {
        dayPicker_availableToStyles = { width: '110px', borderColor: 'rgb(220, 53, 69)' };
        dayPicker_availableToStyles_wide = { width: '300px', borderColor: 'rgb(220, 53, 69)' };
    }

    const dateFormat = otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY';

    if (window.innerWidth < 768) {
        return (
            <div>
                <h1>{translate('Reservation table')}</h1>

                <div className="reservation-filters-wrapper-mobile">
                    <div className="display-flex">
                        <span
                            className="reservation-filters-wrapper-mobile-button button-white-w-icon"
                            onClick={() => {
                                setAvailabilityFiltersShown(!availabilityFiltersShown);
                            }}
                        >
                            <Tick /> {translate('Availability filters')}:&ensp;
                        </span>
                    </div>
                    {availabilityFiltersShown ? (
                        <div className="availabilty-filters-mobile">
                            <div className="availabilty-filters-mobile-header">
                                <span>Availability</span>
                                <span
                                    onClick={() => {
                                        setAvailabilityFiltersShown(false);
                                    }}
                                >
                                    <Close />
                                </span>
                            </div>
                            <div className="DayPickerInput-wrapper">
                                <DayPickerInput /*display depending screen width*/
                                    inputProps={{
                                        style: { width: '114px' },
                                        className: 'form-control form-control-sm ',
                                        readOnly: true,
                                    }}
                                    format={dateFormat}
                                    placeholder={dateFormat}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    value={availableFrom ? new Date(availableFrom) : ''}
                                    onDayChange={(e: any) => handleAvailableFromDatePick(e)}
                                    dayPickerProps={{
                                        numberOfMonths: 1,
                                        showWeekNumbers: true,
                                        disabledDays: {
                                            before: new Date(),
                                        },
                                    }}
                                />

                                {/* <small>&ensp;{window.translate('-')}&ensp;</small> */}

                                <DayPickerInput /*display depending screen width*/
                                    inputProps={{
                                        style: { width: '114px' },
                                        className: 'form-control form-control-sm',
                                        readOnly: true,
                                    }}
                                    format={dateFormat}
                                    placeholder={dateFormat}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    value={
                                        availableTo
                                            ? new Date(availableTo)
                                            : availableFrom2 === ''
                                            ? ''
                                            : new Date(availableFrom2)
                                    }
                                    onDayChange={(e: any) => handleAvailableFromDatePick(e)}
                                    dayPickerProps={{
                                        showWeekNumbers: true,
                                        numberOfMonths: 1,
                                        disabledDays: {
                                            before: availableFrom ? new Date(availableFrom) : new Date(),
                                            after: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
                                        },
                                    }}
                                />
                            </div>
                            {/* <small>&ensp;{window.translate('')}&ensp;</small> */}
                            <input
                                style={{ width: '100%' }}
                                min={0}
                                value={stayInDays === null ? '' : stayInDays}
                                onChange={(e: any) => changeStayInDays(e)}
                                className="input"
                                type="number"
                                placeholder={translate('Days')}
                                title={translate('Days')}
                            />
                            <input
                                onChange={(e: any) => props.setNumberOfBeds(e)}
                                style={{ width: '100%' }}
                                className="input"
                                type="number"
                                title={translate('Beds')}
                                placeholder={translate('Beds')}
                                value={props.numberOfBeds}
                                min={0}
                            />
                            <input
                                onChange={(e: any) => props.setNumberOfSpareBeds(e)}
                                style={{ width: '100%' }}
                                className="input"
                                type="number"
                                title={translate('Spare beds')}
                                placeholder={translate('Spare beds')}
                                value={props.numberOfSpareBeds}
                                min={0}
                            />

                            <div
                                onClick={() => {
                                    clearHandler();
                                }}
                                className="button-primary availibility-filter-button"
                            >
                                {translate('Clear')}
                            </div>
                            {availableTo ? (
                                <div className="">
                                    <div className="checkbox-with-label-wrapper">
                                        <input
                                            onChange={(e) => props.toogleOccupiedRooms(e)}
                                            checked={props.showOccupiedRooms}
                                            id="occ-rooms-check"
                                            type="checkbox"
                                            className="checkbox-primary"
                                        ></input>
                                        <label htmlFor="occ-rooms-check">{translate('Include occupied rooms')}</label>
                                    </div>
                                </div>
                            ) : null}
                            <div
                                onClick={() => props.setShowReservationsList(!props.showReservationsList)}
                                className="button-white-w-icon occupancy-icon"
                            >
                                {/* {this.props.showReservationsList ? <ResList />  :  <Calendar /> } */}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }

    return (
        <div className="reservation-filters-wrapper">
            <span>{translate('Availability')}:&ensp;</span>
            <DayPickerInput /*display depending screen width*/
                inputProps={{
                    style: { width: '110px' },
                    className: 'd-none d-sm-block form-control form-control-sm mt-1 mt-md-0',
                    readOnly: true,
                }}
                format={dateFormat}
                placeholder={dateFormat}
                formatDate={formatDate}
                parseDate={parseDate}
                value={availableFrom ? new Date(availableFrom) : ''}
                onDayChange={(e: any) => handleAvailableFromDatePick(e)}
                dayPickerProps={{
                    numberOfMonths: 1,
                    showWeekNumbers: true,
                    disabledDays: {
                        before: new Date(),
                    },
                }}
            />
            <DayPickerInput /*display depending screen width*/
                inputProps={{
                    style: { width: '300px' },
                    className: 'd-block d-sm-none form-control form-control-sm mt-1 mt-md-0',
                    readOnly: true,
                }}
                format={dateFormat}
                placeholder={dateFormat}
                formatDate={formatDate}
                parseDate={parseDate}
                value={availableFrom ? new Date(availableFrom) : ''}
                onDayChange={(e: any) => handleAvailableFromDatePick(e)}
                dayPickerProps={{
                    numberOfMonths: 1,
                    showWeekNumbers: true,
                    disabledDays: {
                        before: new Date(),
                    },
                }}
            />

            <small>&ensp;{translate('-')}&ensp;</small>
            <DayPickerInput /*display depending screen width*/
                inputProps={{
                    style: dayPicker_availableToStyles,
                    className: 'd-none d-sm-block form-control form-control-sm mt-1 mt-md-0 mr-2',
                    readOnly: true,
                }}
                format={dateFormat}
                placeholder={dateFormat}
                formatDate={formatDate}
                parseDate={parseDate}
                value={availableTo ? new Date(availableTo) : availableFrom2 === '' ? '' : new Date(availableFrom2)}
                onDayChange={(e: any) => handleAvailableToDatePick(e)}
                dayPickerProps={{
                    showWeekNumbers: true,
                    numberOfMonths: 1,
                    //@ts-ignore
                    disabledDays: {
                        before: availableFrom ? new Date(availableFrom) : Date.now(),
                        after: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
                    },
                }}
            />

            <DayPickerInput /*display depending screen width*/
                inputProps={{
                    style: dayPicker_availableToStyles_wide,
                    className: 'd-block d-sm-none form-control form-control-sm mt-1 mt-md-0 mr-2',
                    readOnly: true,
                }}
                format={dateFormat}
                placeholder={dateFormat}
                formatDate={formatDate}
                parseDate={parseDate}
                value={availableTo ? new Date(availableTo) : availableFrom2 === '' ? '' : new Date(availableFrom2)}
                onDayChange={(e: any) => handleAvailableToDatePick(e)}
                dayPickerProps={{
                    showWeekNumbers: true,
                    numberOfMonths: 1,
                    //@ts-ignore
                    disabledDays: {
                        before: availableFrom ? new Date(availableFrom) : Date.now(),
                        after: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
                    },
                }}
            />

            {/* <small>&ensp;{translate('')}&ensp;</small> */}
            <input
                style={{ width: '82px' }}
                min={0}
                value={stayInDays === null ? '' : stayInDays}
                onChange={(e: any) => changeStayInDays(e)}
                className="input"
                type="number"
                placeholder={translate('Days')}
                title={translate('Days')}
            />
            <input
                onChange={(e: any) => props.setNumberOfBeds(e)}
                style={{ width: '82px' }}
                className="input"
                type="number"
                title={translate('Beds')}
                placeholder={translate('Beds')}
                value={props.numberOfBeds}
                min={0}
            />

            <input
                onChange={(e: any) => props.setNumberOfSpareBeds(e)}
                style={{ width: '82px' }}
                className="input"
                type="number"
                title={translate('Spare beds')}
                placeholder={translate('Spare beds')}
                value={props.numberOfSpareBeds}
                min={0}
            />

            <FeatureFlag flag="groupInfos">
                <div onClick={clearHandler} className="button-primary">
                    {translate('Clear')}
                </div>
            </FeatureFlag>
            {availableTo ? (
                <div className="">
                    <div className="checkbox-with-label-wrapper">
                        <input
                            onChange={(e) => props.toogleOccupiedRooms(e)}
                            checked={props.showOccupiedRooms}
                            id="occ-rooms-check"
                            type="checkbox"
                            className="checkbox-primary"
                        ></input>
                        <label htmlFor="occ-rooms-check">{translate('Include occupied rooms')}</label>
                    </div>
                </div>
            ) : null}

            <div className="ml-auto flex-center-center">
                <div
                    className="custom-control custom-switch"
                    onClick={async () => {
                        props.setShowReservationsList(!props.showReservationsList);
                    }}
                >
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        // id="customSwitch1"
                        checked={!props.showReservationsList ? true : false}
                        onChange={() => {
                            return null;
                        }}
                    />
                    <label className="custom-control-label pointer" style={{ backgroundColor: '#1068ec' }} htmlFor="">
                       
                    </label>
                </div>
                <div className='font-12p mr-4p'>{translate('Show summary')}</div>
            </div>
        </div>
    );
}

export default FromToFilter;
