import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Controls from './components/Controls';
import Layout from './components/Layout';
import createTime from './utils/time';

import './scss/style.scss';

const UNKNOWN_WIDTH = -1;

class Timeline extends Component {
    constructor(props) {
        super(props);
        const timelineViewportWidth = UNKNOWN_WIDTH;
        const sidebarWidth = UNKNOWN_WIDTH;
        this.state = {
            time: createTime({ ...props.scale, viewportWidth: timelineViewportWidth }),
            timelineViewportWidth,
            sidebarWidth,
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { scale } = this.props;
        const { timelineViewportWidth } = this.state;

        if (nextProps.scale !== scale) {
            const time = createTime({
                ...nextProps.scale,
                viewportWidth: timelineViewportWidth,
            });
            this.setState({ time });
        }
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     const {
    //         scale,
    //         isOpen,
    //         showControls,
    //         tracks,
    //         timebar,
    //         now,
    //         enableSticky,
    //         scrollToNow,
    //         style,
    //         onDragStart,
    //         onDrop,
    //     } = this.props;
    //     // const skipProps = ['onDragStart', 'onDrop'];
    //     let shouldUpdate = false;

    //     // Compare props and log changes
    //     if (nextProps.scale.start.getTime() !== scale.start.getTime()) {
    //         console.log(`Prop 'scale.start' changed.cale.start}`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.scale.end.getTime() !== scale.end.getTime()) {
    //         console.log(`Prop 'scale.end' changed.cale.end}`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.scale.zoom !== scale.zoom) {
    //         console.log(`Prop 'scale.zoom' changed.cale.zoom}`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.scale.zoomMin !== scale.zoomMin) {
    //         console.log(`Prop 'scale.zoomMin' changed.cale.zoomMin}`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.scale.zoomMax !== scale.zoomMax) {
    //         console.log(`Prop 'scale.zoomMax' changed.cale.zoomMax}`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.isOpen !== isOpen) {
    //         console.log(`Prop 'isOpen' changed.`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.showControls !== showControls) {
    //         console.log(`Prop 'showControls' changed.`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.tracks !== tracks) {
    //         console.log('tracks', tracks);
    //         // Loop through the current and next 'tracks' arrays
    //         if (nextProps.tracks.length > 0 && tracks.length > 0) {
    //             const tracksChanged = nextProps.tracks.some((track, index) => {
    //                 // Check if the Elements array length has changed at any index
    //                 return track.elements?.length !== tracks[index]?.elements?.length;
    //             });
    //             // If any track's Elements array length has changed, set shouldUpdate to true
    //             if (tracksChanged) {
    //                 console.log(`Prop 'tracks' changed: Length of 'Elements' array in a track has changed`);
    //                 shouldUpdate = true;
    //             }
    //         } else {
    //             shouldUpdate = true;
    //         }
    //     }
    //     if (nextProps.timebar !== timebar) {
    //         console.log(`Prop 'timebar' changed`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.now?.getTime() !== now?.getTime()) {
    //         console.log(`Prop 'now' changed.`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.enableSticky !== enableSticky) {
    //         console.log(`Prop 'enableSticky' changed.`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.scrollToNow !== scrollToNow) {
    //         console.log(`Prop 'scrollToNow' changed.`);
    //         shouldUpdate = true;
    //     }
    //     if (JSON.stringify(nextProps.style) !== JSON.stringify(style)) {
    //         console.log(`Prop 'style' changed.SON.stringify(nextProps.style)}`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.onDragStart !== onDragStart) {
    //         console.log(`Prop 'onDragStart' changed`);
    //         shouldUpdate = true;
    //     }
    //     if (nextProps.onDrop !== onDrop) {
    //         console.log(`Prop 'onDrop' changed.}`);
    //         shouldUpdate = true;
    //     }

    //     return shouldUpdate;
    // }

    handleLayoutChange = ({ timelineViewportWidth, sidebarWidth }, cb) => {
        const { scale } = this.props;
        const time = createTime({
            ...scale,
            viewportWidth: timelineViewportWidth,
        });
        this.setState(
            {
                time,
                timelineViewportWidth,
                sidebarWidth,
            },
            cb
        );
    };

    render() {
        const {
            showHeader = true,
            isOpen = true,
            toggleOpen,
            zoomIn,
            zoomOut,
            scale: { zoom, zoomMin, zoomMax },
            tracks,
            now,
            timebar,
            toggleTrackOpen,
            enableSticky = false,
            scrollToNow,
            showControls = false,
            style = {},
            onDragStart,
            onDrop,
        } = this.props;

        const { time, timelineViewportWidth, sidebarWidth } = this.state;

        const { clickElement, clickTrackButton, trackButtonIcon } = this.props;

        return (
            <div className="rt">
                {showControls ? (
                    <Controls
                        isOpen={isOpen}
                        toggleOpen={toggleOpen}
                        zoomIn={zoomIn}
                        zoomOut={zoomOut}
                        zoom={zoom}
                        zoomMin={zoomMin}
                        zoomMax={zoomMax}
                    />
                ) : null}
                <Layout
                    showHeader={showHeader}
                    style={style}
                    enableSticky={enableSticky}
                    now={now}
                    tracks={tracks}
                    timebar={timebar}
                    toggleTrackOpen={toggleTrackOpen}
                    scrollToNow={scrollToNow}
                    time={time}
                    isOpen={isOpen}
                    onLayoutChange={this.handleLayoutChange}
                    timelineViewportWidth={timelineViewportWidth}
                    sidebarWidth={sidebarWidth}
                    clickElement={clickElement}
                    clickTrackButton={clickTrackButton}
                    trackButtonIcon={trackButtonIcon}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                />
            </div>
        );
    }
}

Timeline.propTypes = {
    scale: PropTypes.shape({
        start: PropTypes.instanceOf(Date).isRequired,
        end: PropTypes.instanceOf(Date).isRequired,
        zoom: PropTypes.number.isRequired,
        zoomMin: PropTypes.number,
        zoomMax: PropTypes.number,
        minWidth: PropTypes.number,
    }),
    style: PropTypes.object,
    isOpen: PropTypes.bool,
    toggleOpen: PropTypes.func,
    zoomIn: PropTypes.func,
    zoomOut: PropTypes.func,
    clickElement: PropTypes.func,
    clickTrackButton: PropTypes.func,
    trackButtonIcon: PropTypes.element,
    timebar: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tracks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    now: PropTypes.instanceOf(Date),
    toggleTrackOpen: PropTypes.func,
    enableSticky: PropTypes.bool,
    scrollToNow: PropTypes.bool,
    showControls: PropTypes.bool,
};

export default Timeline;
